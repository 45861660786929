import { useEffect, useState } from "react";
import AddNotifications from "./components/addNotifications";
import Common from "../../helper/common";
import { Button } from "../../components/ui/button";
import AdvanceTable from "../../components/advanceTable";
import { PushNotificationTypeEnum, ViewDateTimeFormat } from "../../constants/appConstant";
import { AppSelect } from "../../components/appComponents";
import moment from "moment";

const Notifications = () => {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [refId, setRefId] = useState(null);
    const [NotificationType, setNotificationType] = useState(null);

    useEffect(() => {
        getData(1);
    }, []);

    useEffect(() => {
        getData(1);
    }, [NotificationType]);

    useEffect(() => {
        if (!open && refId) {
            setRefId();
        }
    }, [open]);

    const getData = async (
        pageNumber,
        searchTerm = "",
        recordsToDisplay = null
    ) => {
        const response = await Common.ApiService.getInstance().request(
            "GetAllNotification",
            {
                pageNumber: pageNumber,
                q: searchTerm,
                isactive: true,
                recordsToDisplay,
                NotificationType,
            },
            "Post"
        );

        if (response?.data) {
            let obj = {
                ...response.data[0],
                data: response.data[0].data.map((item) => ({
                    ...item,
                    createdOn: `${moment(item.createdOn).format(ViewDateTimeFormat)}`,
                })),
            };

            setData(obj);
        }

        setLoading(false);

    };

    let _thead = [
        { title: "Id", type: "number", isVisible: false, key: "id" },
        { title: "Title", type: "text", isVisible: true, key: "title", style: { width: "50px" } },
        { title: "Content", type: "text", isVisible: true, key: "content", style: { width: "250px" } },
        { title: "Total Delivered", type: "text", isVisible: true, key: "totalDelivered" },
        { title: "Total Token", type: "text", isVisible: true, key: "totalToken" },
        { title: "Created On", type: "text", isVisible: true, key: "createdOn" },
        {
            title: "Type",
            type: "text",
            isVisible: true,
            key: "typeName",
            render: (record) =>
                Common.Utility.renderCustomBadge(record.type, record.typeName, PushNotificationTypeEnum, {
                    1: "warning",
                    2: "destructive",
                    3: "info",
                }),
        },
    ];



    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <div className="md:grid md:grid-cols-6 grid grid-cols-2 gap-x-2 ">
            <AppSelect
                value={NotificationType}
                onChange={setNotificationType}
                placeholder="Select Notification Type"
                    label="Notification Type"
                data={[
                    { id: null, name: "Select" },
                    ...Common.Utility.enumToArray(PushNotificationTypeEnum),
                ]}
                className="mb-3 col-span-1"
                />
            </div>

            {data?.data && (
                <AdvanceTable
                    title={"Notifications"}
                    columns={_thead.filter((item) => item.isVisible)}
                    data={data}
                    getData={getData}
                    addButton={() => (
                        <AddNotifications
                            id={refId}
                            onClose={() => getData(1)}
                            open={open}
                            setOpen={setOpen}
                        />
                    )}
                    showPagination
                    showAddButton
                />
            )}
        </>
    );
};

export default Notifications;
