import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import AddCustomer from "./components/addCustomer";
import Common from "../../helper/common";
import { Button } from "../../components/ui/button";
import AdvanceTable from "../../components/advanceTable";
import {SearchTypeEnum} from "../../constants/appConstant";

const ClubCustomers = () => {
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  const [customerId, setCustomerId] = useState();

  useEffect(() => {
    getData(1);
  }, []);

  useEffect(() => {
    if (!open) {
      setCustomerId();
    }
  }, [open]);

  const getData = async (
    pageNumber,
    searchTerm = "",
    recordsToDisplay = null
  ) => {
      debugger
    const response = await Common.ApiService.getInstance().request(
      "ClubPlayers",
      {
        pageNumber: pageNumber,
        q: searchTerm,
        recordsToDisplay,

      },
      "Post"
    );
    if (response?.data) {
      let obj = {
        ...response.data[0],
        data: response.data[0].data.map((item) => ({
          ...item,
          phone: `${item.phoneCode ?? ""}-${item.phone ?? ""}`,
          email: item.email ?? "_",
        })),
      };

      setData(obj);
    }
  };

  const _addUpdateTrigger = () => (
    <Button size="icon" variant="outline" color="secondary" className="h-7 w-7">
      <Icon icon="heroicons:pencil" className="h-4 w-4" />
    </Button>
    
  );

  const actions = [
    {
      type: "edit",
      onClick: (val) => {
        global.log("val", val);
        setOpen(true);
        setCustomerId(val.id);
      },
    },
  ];

  let columns = [
    { title: "First Name", type: "text", key: "firstName" },
    { title: "Last Name", type: "text", key: "lastName" },
    { title: "Email", type: "text", key: "email" },
    { title: "Phone #", type: "text", key: "phone" },
  ];


  return (
    <>
      {data?.data && (
        <AdvanceTable
                  title={"Customers"}
                  columns={columns}
                  data={data}
                  getData={getData}
                  addButton={() => (
                      <AddCustomer
                          id={customerId}
                          onClose={(val) => getData()}
                          open={open}
                          setOpen={setOpen}
                          bookingModal={false}
                      />
                  )}
                  showPagination
                  showAddButton
                  actionButtons={actions}
                  updateTrigger={_addUpdateTrigger} // Example function
                  search={SearchTypeEnum.Server_Search}
        />
      )}
    </>
  );
};

export default ClubCustomers;
