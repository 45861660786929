import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  calendarDate: new Date(),
  sportId: null,
  customerId: null,
  paymentStatus: null,
  bookingStatus: null,
  courtId: null,
  bookingMode: null,
  fromDateTime: null,
  endDateTime: null,
  bookingType: null,
  matchType: null,
  paymentMethod: null,
  paymentTypeId: null,
};

const bookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    setCalendarDate: (state, action) => {
      state.calendarDate = action.payload;
    },
    setSportId: (state, action) => {
      state.sportId = action.payload;
    },
    updateCustomerId: (state, action) => {
      state.customerId = action.payload;
    },
    updatePaymentStatus: (state, action) => {
      state.paymentStatus = action.payload;
    },
    updateBookingStatus: (state, action) => {
      state.bookingStatus = action.payload;
    },
    updateCourtId: (state, action) => {
      state.courtId = action.payload;
    },
    updateBookingMode: (state, action) => {
      state.bookingMode = action.payload;
    },
    updateFromDateTime: (state, action) => {
      state.fromDateTime = action.payload;
    },
    updateEndDateTime: (state, action) => {
      state.endDateTime = action.payload;
    },
    updateBookingType: (state, action) => {
      state.bookingType = action.payload;
    },
    updateMatchType: (state, action) => {
      state.matchType = action.payload;
    },
    updatePaymentMethod: (state, action) => {
      state.paymentMethod = action.payload;
    },
    updatePaymentTypeId: (state, action) => {
      state.paymentTypeId = action.payload;
      },
    
      resetCalenderBooking: (state) => {
      state.calendarDate = new Date();
      state.sportId = null;
      //state.customerId = null;
      //state.paymentStatus = null;
      //state.bookingStatus = null;
      //state.courtId = null;
      //state.bookingMode = null;
      //state.fromDateTime = null;
      //state.endDateTime = null;
      //state.bookingType = null;
      //state.matchType = null;
      //state.paymentMethod = null;
      //state.paymentTypeId = null;
      },

      
      resetBooking: (state) => {
          state.customerId = null;
          state.paymentStatus = null;
          state.bookingStatus = null;
          state.courtId = null;
          state.bookingMode = null;
          state.fromDateTime = null;
          state.endDateTime = null;
          state.bookingType = null;
          state.matchType = null;
          state.paymentMethod = null;
          state.paymentTypeId = null;
      },
  },
});

export const {
  setCalendarDate,
  setSportId,
    resetBooking,
    resetCalenderBooking,
  updateCustomerId,
  updatePaymentStatus,
  updateBookingStatus,
  updateCourtId,
  updateBookingMode,
  updateFromDateTime,
  updateEndDateTime,
  updateBookingType,
  updateMatchType,
  updatePaymentMethod,
  updatePaymentTypeId,
} = bookingSlice.actions;
export default bookingSlice.reducer;

// Selector functions to get state values
export const getDate = (state) => state.booking.calendarDate;
export const getSportId = (state) => state.booking.sportId;
