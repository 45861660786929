import "./App.css";
import "./assets/scss/globals.scss";
import "./assets/scss/theme.scss";
import { DefaultSpinner, LoaderContainer } from "react-global-loader";
import { ThemeProvider } from "./contexts/themeContext";
import { AuthProvider } from "./contexts/authContext";
import { cn } from "./helper/utilityHelper";
import { RouterProvider } from "react-router-dom";
import { router } from "./router";
import { Toaster } from "react-hot-toast";
import { Toast, ToastProvider } from "./components/ui/toast";
import { ReactToaster } from "./components/ui/toaster";
import LayoutLoader from "./components/layout-loader";
import { MenuProvider } from "./contexts/menuContext";
import { QueryParamProvider } from "./contexts/queryParamsContext";
import { Provider } from "react-redux";
import { store } from "./store/store";

function App() {
  return (
    <Provider store={store}>
      <QueryParamProvider>
        <MenuProvider>
          <AuthProvider>
            <ThemeProvider>
              <div className={cn("dash-tail-app theme-light")}>
                <LoaderContainer opacity={0.7} backgroundColor="#fff">
                  <LayoutLoader />
                </LoaderContainer>

                <RouterProvider router={router} />
                <Toaster />
                <ReactToaster />
              </div>
            </ThemeProvider>
          </AuthProvider>
        </MenuProvider>
      </QueryParamProvider>
    </Provider>
  );
}

export default App;
