import React, { useMemo, useState } from "react";
import {
    Table,
    TableBody,
    TableHeader,
    TableHead,
    TableRow,
    TableCell,
} from "../ui/table";
import { Switch } from "../ui/switch";
import Card from "../ui/card-snippet";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";
import { StatusTag, getStatusColor } from "../ui/statusTag";
import AdvancedTablePagination from "./components/advancedTablePagination";
import ActionButton from "./components/actionButton";
import Common from "../../helper/common";
import { SearchTypeEnum, PaymentStatusEnum } from "../../constants/appConstant";
import {
    ExportAsExcel,
    ExportAsPdf,
    ExportAsCsv,
    CopyToClipboard,
    CopyTextToClipboard,
    PrintDocument,
    ExcelToJsonConverter,
    FileUpload,
} from "react-export-table";
import { NiCopy, Csv, NiExcel, NiPdf, NiPrint } from "../../svg";

const getStatusColorRow = (status) => {
    switch (status) {
        case PaymentStatusEnum.Paid:
            return "bg-[#d6ffe5]";
        case PaymentStatusEnum.Un_Paid:
            return "bg-[#ffe4e4]";
        case PaymentStatusEnum.Partial_Paid:
            return "bg-[#ffd5b8]";
        default:
            return "bg-[#fff]";
    }
};

const AdvanceTable = ({
    title,
    columns,
    getData,
    data,
    actionButtons = [],
    showPagination = false,
    addButton,
    showExportoptions = false,
    search = null,
}) => {
    const _columns = useMemo(() => columns.map((item) => item.title), [columns]);
    const _data = useMemo(
        () => data?.data?.map((item) => columns.map((column) => item[column.key])) || [],
        [data?.data, columns]
    );

    const [searchQuery, setSearchQuery] = useState("");
    const filteredData = useMemo(() => {
        if (!searchQuery) return data?.data || [];
        return data?.data.filter((item) =>
            columns.some((column) =>
                item[column.key]?.toString()?.toLowerCase()?.includes(searchQuery.toLowerCase())
            )
        );
    }, [searchQuery, data, columns]);

    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchQuery(value);
        if (search === SearchTypeEnum.Server_Search && getData) getData(1, value);
    };

    const ExportButton = ({ props, title }) => <button {...props}>{title}</button>;

    const _renderExportOptions = () => {
        if (!showExportoptions) {
            return addButton ? addButton() : null;
        }

        return (
            <div className="flex flex-row gap-4 items-center">
                <CopyToClipboard data={_data} headers={_columns}>
                    {(props) => <ExportButton props={props} title={<NiCopy />} />}
                </CopyToClipboard>
                <ExportAsPdf data={_data} headers={_columns} headerStyles={{ fillColor: "red" }}>
                    {(props) => <ExportButton props={props} title={<NiPdf />} />}
                </ExportAsPdf>
                <ExportAsExcel data={_data} headers={_columns}>
                    {(props) => <ExportButton props={props} title={<NiExcel />} />}
                </ExportAsExcel>
                <PrintDocument data={_data} headers={_columns}>
                    {(props) => <ExportButton props={props} title={<NiPrint />} />}
                </PrintDocument>
                {addButton ? addButton() : null}
            </div>
        );
    };

    return (
        <div className="space-y-6">
            <Card
                title={title}
                addButton={_renderExportOptions}
                searchQuery={search !== null ? searchQuery : null}
                handleSearchChange={search !== null ? handleSearchChange : null}
            >
                <Table>
                    <TableHeader>
                        <TableRow className="bg-secondary">
                            {columns.map((column) => (
                                <TableHead key={column.key} className="font-semibold whitespace-nowrap" style={column.style}>
                                    {column.title}
                                </TableHead>
                            ))}

                            {actionButtons.length ? (
                                <TableHead className="text-right w-20 sticky right-0 bg-background drop-shadow-md">
                                    Action
                                </TableHead>
                            ) : null}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {filteredData.map((item, index) => (
                            <TableRow key={index} className="hover:bg-muted">
                                {columns.map((column, cellIndex) => {
                                    switch (column.type) {
                                        case "image":
                                            return (
                                                <TableCell key={cellIndex} className="font-medium text-card-foreground/80 whitespace-nowrap">
                                                    <div className="flex space-x-3 rtl:space-x-reverse items-center">
                                                        <Avatar className="rounded-lg">
                                                            <AvatarImage src={Common.AppConstant.imgBaseurl + item[column.key]} />
                                                            <AvatarFallback>AB</AvatarFallback>
                                                        </Avatar>
                                                        <span className="text-sm text-default-600">{item.firstName}</span>
                                                    </div>
                                                </TableCell>
                                            );
                                        case "text":
                                            return (
                                                <TableCell key={cellIndex} className="whitespace-nowrap" style={column.style}>
                                                    {column.render ? column.render(item) : item[column.key]}
                                                </TableCell>
                                            );
                                        case "toggle":
                                            return (
                                                <TableCell key={cellIndex}>
                                                    <Switch checked={item[column.key]} onCheckedChange={() => column.onChanged?.(item)} />
                                                </TableCell>
                                            );
                                        case "status":
                                            return (
                                                <TableCell key={cellIndex}>
                                                    <StatusTag tagcolor={getStatusColor(item.status)} text={item.status} />
                                                </TableCell>
                                            );
                                        default:
                                            return <TableCell key={cellIndex}>{item[column.key]}</TableCell>;
                                    }
                                })}
                                {actionButtons.length > 0 && (
                                    <TableCell bgColor={getStatusColorRow(item.paymentStatusId)} className="text-right sticky right-0 drop-shadow-md">
                                        <div className="flex space-x-0 rtl:space-x-reverse gap-1">
                                            {actionButtons
                                                .filter((action) =>
                                                    action.visibleProp ? item[action.visibleProp] === action.visibleValue : true
                                                )
                                                .map((action) =>
                                                    action.type == "edit" ? (
                                                        <ActionButton
                                                            icon="heroicons:pencil"
                                                            onClick={action.onClick?.bind(this, item)}
                                                        />
                                                    ) : action.type == "custom" ? (
                                                        action.button(item)
                                                    ) : action.type == "customIcon" ? (
                                                        <ActionButton
                                                            icon={`heroicons:${action.icon}`}
                                                            onClick={action.onClick?.bind(this, item)}
                                                        />
                                                    ) : (
                                                        <ActionButton
                                                            icon={`heroicons:${action.type == "delete" ? "trash" : "eye"
                                                                }`}
                                                            onClick={action.onClick?.bind(this, item)}
                                                        />
                                                    )
                                                )}
                                        </div>
                                    </TableCell>
                                )}
                                {/* <TableCell className="text-right sticky right-0 bg-background drop-shadow-md">
                  <div className="flex space-x-3 rtl:space-x-reverse">
                    <ActionButton
                      icon="heroicons:pencil"
                      onClick={() => updateTrigger(item.id)}
                    />
                  </div>
                </TableCell> */}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {showPagination && data && (
                    <AdvancedTablePagination
                        currentPage={data.pageNumber}
                        onPageChanged={getData}
                        totalPages={data.totalPages}
                        displayCount={data.totalDisplayed / data.pageNumber}
                        clubcustomers
                    />
                )}
            </Card>
        </div>
    );
};
export default AdvanceTable;
