import React from "react";
import { Link, useLocation } from "react-router-dom";
import { isLocationMatch, cn } from "../../../../helper/utilityHelper";
import { Badge } from "../../../../components/ui/badge";
import { useSidebar } from "../../../../store";
import { useDispatch } from "react-redux";
import { resetBooking } from "../../../../store/bookingSlice";
function LockLink({ href, children, subItem }) {
  const { setMobileMenu } = useSidebar();
  const dispatch = useDispatch();
  if (subItem.badge) {
    return (
      <span className="text-sm flex space-x-3 items-center transition-all duration-150 opacity-50  cursor-not-allowed">
        <span
          className={` h-2 w-2 rounded-full border border-zinc-600 dark:border-white inline-block flex-none`}
        ></span>
        <div className="flex-1 truncate  flex  text-zinc-600 dark:text-zinc-300">
          <div className="flex-1  truncate">{subItem.title}</div>
          <Badge className="leading-0 capitalize flex-none px-1 text-xs font-normal">
            {subItem.badge}
          </Badge>
        </div>
      </span>
    );
  } else {
    return (
      <Link
        to={href}
        onClick={() => {
          if (
            href.toString().includes("clubbookings") ||
            href.toString().includes("bookingcalendar")
          ) {
            dispatch(resetBooking());
          }
          setMobileMenu(false);
        }}
      >
        {children}
      </Link>
    );
  }
}

const SubMenuItem = ({ subItem }) => {
  const locationName = useLocation().pathname;
  return (
    <LockLink href={subItem.href} subItem={subItem}>
      <div
        className={cn(
          "text-sm  font-normal flex gap-3 items-center transition-all duration-150 rounded dark:hover:text-primary  ",
          {
            " text-primary   ": isLocationMatch(subItem.href, locationName),
            "  text-default-600 dark:text-default-700  ": !isLocationMatch(
              subItem.href,
              locationName
            ),
          }
        )}
      >
        <span className="flex-1 truncate">{subItem.title}</span>
      </div>
    </LockLink>
  );
};

export default SubMenuItem;
