import React, { useEffect, useState, Fragment } from "react";
import { Icon } from "@iconify/react";
import { useDropzone } from "react-dropzone";
import { Upload } from "lucide-react";
import { Button } from "../ui/button";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";

const FormikUploaderMultipleCarousel = ({
    formikProps,
    imagesOnly = true,
    title = "Drop files here or click to upload.",
    description = "(This is just a demo drop zone. Selected files are not actually uploaded.)",
    maxFileUploadSize = 3,
    renderImagetoState = [],
    renderImage = true,
}) => {
    const [files, setFiles] = useState([]);
    const [error, setError] = useState(""); // State to handle error messages

    //useEffect(() => {
    //
    //    if (renderImagetoState?.length > 0) {
    //        setFiles(renderImagetoState);
    //    }
    //}, [renderImagetoState]);

    let acceptedFileTypes = imagesOnly
        ? {
            "image/png": [],
            "image/jpeg": [],
            "image/jpg": [],
        }
        : {};

    const { getRootProps, getInputProps } = useDropzone({
        accept: acceptedFileTypes,
        onDrop: (acceptedFiles) => {
            let hasError = false;
            const filteredFiles = acceptedFiles.filter((file) => {
                const isValidType =
                    !Object.keys(acceptedFileTypes).length ||
                    Object.keys(acceptedFileTypes).some((key) => key === file.type);
                const isUnderMaxSize = file.size <= maxFileUploadSize * 1024 * 1024;

                if (!isUnderMaxSize) {
                    hasError = true;
                    setError(
                        `File "${file.name}" is too large. Maximum size is ${maxFileUploadSize}MB.`
                    );
                }
                return isValidType && isUnderMaxSize;
            });

            if (!hasError) setError(""); // Clear error if no issues

            setFiles((prevFiles) => [...prevFiles, ...filteredFiles]);
            //onFilesUpdate((prevImage) => [...prevImage, ...filteredFiles]);
        },
    });

    useEffect(() => {
        if (files.length) {
            formikProps.setFieldValue("files", files);
        }
    }, [files]);

    const handleRemoveFile = (file) => {
        const filteredFiles = files.filter((i) => i.name !== file.name);
        setFiles(filteredFiles);
    };

    const handleRemoveAllFiles = () => {
        setFiles([]);
    };

    return (
        <Fragment>
            <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                <div className="w-full text-center border-dashed border rounded-md py-[52px] flex items-center flex-col">
                    <div className="h-12 w-12 inline-flex rounded-md bg-muted items-center justify-center mb-3">
                        <Upload className="h-6 w-6 text-default-500" />
                    </div>
                    <h4 className="text-2xl font-medium mb-1 text-card-foreground/80">
                        {title}
                    </h4>
                    <div className="text-xs text-muted-foreground">{description}</div>
                </div>
            </div>

            {/* Display error message */}
            {error && (
                <div className="text-red-500 text-sm mt-2">{error}</div>
            )}

            {renderImage && files.length ? (
                <Fragment>
                    <h3 className="text-lg font-semibold mt-4 mb-2">New Images</h3>
                    <Swiper
                        navigation={true}
                        modules={[Navigation]}
                        slidesPerView={3}
                        spaceBetween={10}
                        className="mySwiper mt-4"
                    >
                        {files.map((file) => (
                            <SwiperSlide key={file.name} className="relative">
                                <img
                                    src={URL.createObjectURL(file)}
                                    alt={file.name}
                                    className="w-full h-64 object-cover rounded-md"
                                />
                                <button
                                    className="absolute top-2 right-2 bg-red-500 text-white p-2 rounded-full"
                                    onClick={() => handleRemoveFile(file)}
                                >
                                    <Icon icon="tabler:x" className="h-5 w-5" />
                                </button>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    {/*<div className="flex justify-end space-x-2 mt-4">*/}
                    {/*    <Button color="destructive" onClick={handleRemoveAllFiles}>*/}
                    {/*        Remove All*/}
                    {/*    </Button>*/}
                    {/*</div>*/}
                </Fragment>
            ) : null}
        </Fragment>
    );
};

export default FormikUploaderMultipleCarousel;
