import { useEffect, useState } from "react";
import { loader } from "react-global-loader";
import { Icon } from "@iconify/react";
import { Button } from "../../components/ui/button";
import Common from "../../helper/common";
import AdvanceTable from "../../components/advanceTable";
import { useNavigate } from "react-router-dom";

const Sports = () => {
  const [sports, setSports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [refId, setRefId] = useState();

  const navigate = useNavigate();

  const getSports = async () => {
    setLoading(true);
      const response = await Common.ApiService.getInstance().request("GetSports");
      
      if (response?.data) {
          let data = response?.data?.map((item) => ({
              ...item,
              question: renderButton(item.hasQuestion, item.id),
              isactive: item.isactive ? "Yes" : "No",
              isOnMobile: item.isOnMobile ? "Yes" : "No",
          }));

          setSports(data);
          setLoading(false);
      }
  };

  useEffect(() => {
    //loader.hide();
    getSports();
  }, []);

  useEffect(() => {
    if (!open && refId) {
      setRefId();
    }
  }, [open]);


  const _onIsOnMobileChange = (obj) => {
    setSports(
      sports.map((item) =>
        item.id == obj.id ? { ...item, isOnMobile: !item.isOnMobile } : item
      )
    );
    };

    const _handleDelete = async (id) => {
        const isConfirmed = await Common.SweetAlert.confirmation(
            "Are you sure you want to delete this sports?",
            "Delete Sports"
        );
        if (isConfirmed) {
            let resp = await Common.ApiService.getInstance().request(
                `DeleteSport?id=${id}`,
                {},
                "Delete"
            );
            Common.SweetAlert.alert(resp.message, "success");
            setTimeout(() => {
                getSports(1);
            }, 500);
        }
    };

    const renderButton = (hasQuestion, id) => {
        if (!hasQuestion) return <div></div>;

        return (
            <div>
                <button
                    style={{
                        backdropFilter: "blur(10px)",
                        background: "rgba(43, 43, 138, 0.7)",
                        color: "white",
                        padding: "4px 7px",
                        borderRadius: "12px",
                        border: "1px solid rgba(255, 255, 255, 0.3)",
                        cursor: "pointer",
                        fontSize: "12px",
                        fontWeight: "bold",
                        boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.3)",
                        transition: "transform 0.3s ease, background 0.3s ease",
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                    }}
                    onClick={() => {
                        navigate(`/app/addQuestion?sportid=${id}`);
                    }}
                    onMouseOver={(e) => {
                        e.currentTarget.style.background = "rgba(43, 43, 180, 0.9)";
                        e.currentTarget.style.transform = "scale(1.05)";
                    }}
                    onMouseOut={(e) => {
                        e.currentTarget.style.background = "rgba(43, 43, 138, 0.7)";
                        e.currentTarget.style.transform = "scale(1)";
                    }}
                >
                    Questions
                    <Icon icon="heroicons:question-mark-circle" style={{ fontSize: "18px" }} />

                </button>
            </div>
        );
    };


  const _thead = [
    { title: "Id", type: "number", isVisible: false, key: "id" },
    { title: "Sport Name", type: "text", isVisible: true, key: "name" },
    { title: "Icon", type: "image", isVisible: true, key: "icon" },
    { title: "Description", type: "text", isVisible: false, key: "desc" },
    { title: "Is On Mobile", type: "text", isVisible: true, key: "isOnMobile", onChanged: _onIsOnMobileChange, },
    { title: "Is Active", type: "text", isVisible: true, key: "isactive" },
    { title: "Question", type: "html", isVisible: true,key: "question" },
  ];

  //const _onEdit = (val) => {
  //    setOpen(true);
  //    setRefId(val.id);
  //};

  const actions = [
    {
      type: "edit",
      onClick: (val) => {
        global.log(val);
        navigate(`/app/addSport?id=${val.id}`, {});
      },
      // onClick: _onEdit,
    },
    {
        type: "delete",
        onClick: (val) => _handleDelete(val.id),

    },
  ];

  if (loading) {
    return <div>Loading...</div>;
  }

  //const AddUpdateSport = () => (
  //  <AddUpdateModal
  //    setOpen={setOpen}
  //    size={"600px"}
  //    Component={() => (
  //      <AddSport
  //        id={refId}
  //        onClose={(val) => {
  //          setOpen(false);
  //          val && getSports();
  //        }}
  //      />
  //    )}
  //    title={refId ? "Edit Sport" : "Add Sport"}
  //    open={open}
  //  />
  //);

  //const _addBtn = () => (
  //  <Button onClick={() => setOpen(true)}>{"Add New"}</Button>
  //  );

  const _addUpdateTrigger = () => (
    <Button size="icon" variant="outline" color="secondary" className="h-7 w-7">
      <Icon icon="heroicons:pencil" className="h-4 w-4" />
    </Button>
  );


    const _addBtn = () => (
        <>
            <Button
                onClick={() => navigate("/app/addSport")}
                style={{
                    marginRight: "10px", // Added margin to the right for spacing
                }}
            >
                <span className="text-xl mr-1">
                    <Icon icon="icon-park-outline:plus" />
                </span>
                Add Sport
            </Button>

            <Button onClick={() => navigate("/app/addQuestion")}>
                <span className="text-xl mr-1">
                    <Icon icon="icon-park-outline:plus" />
                </span>
                Add Question
            </Button>
        </>
    );


  return (
    <>
      <AdvanceTable
        title={"Sports"}
        columns={_thead.filter((item) => item.isVisible)}
        data={{ data: sports }}
        addButton={_addBtn}
        showPagination={false}
        showAddButton
        actionButtons={actions}
        updateTrigger={_addUpdateTrigger}
      />
      {/* <AddUpdateSport />*/}
    </>
  );
};

export default Sports;
