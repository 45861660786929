import React from "react";
import { Input } from "../ui/input";
import ErrorText from "./error-text";
import { Label } from "../ui/label";

export default function FormikInput({
  formikProps,
  onChange = () => {},
  name,
  placeholder,
  style,
  className, // other Props
  type,
  size,
  color,
  radius,
  variant,
  shadow,
  isPhoneNumber = false,
  removeWrapper = false,
  label,
  min,
  max,
  maxLength,
  disabled,
  ...props
}) {
  const handleOnChange = (e) => {
    const { value } = e.target;
    if (isPhoneNumber) {
      let numericValue = value.replace(/[^\d]/g, "");

      if (numericValue.length > 3 && numericValue.length <= 7) {
        numericValue = numericValue.slice(0, 3) + "-" + numericValue.slice(3);
      } else if (numericValue.length > 7) {
        numericValue =
          numericValue.slice(0, 3) + "-" + numericValue.slice(3, 10);
      }

      formikProps.setFieldValue(name, numericValue);
      onChange(numericValue);
    } else {
      formikProps.setFieldValue(name, value);
      onChange(value);
    }
  };

  return (
    <div>
      <style>
        {`
          input[type="number"]::-webkit-outer-spin-button,
          input[type="number"]::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        `}
      </style>
      {label && (
        <Label className="mb-3" htmlFor="inputId">
          {label}
        </Label>
      )}
      <Input
        value={formikProps.values[name]}
        //  onChange={(e) => {
        //    formikProps.setFieldValue(name, e.target.value);
        //    onChange(e.target.value);
        //  }}
        onChange={handleOnChange}
        placeholder={placeholder}
        style={style}
        className={className}
        type={type}
        size={size}
        color={color}
        radius={radius}
        variant={variant}
        shadow={shadow}
        removeWrapper={removeWrapper}
        min={min}
        max={max}
        maxLength={maxLength}
        disabled={disabled}
        {...props}
      />
      <ErrorText formikProps={formikProps} name={name} />
    </div>
  );
}
