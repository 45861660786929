import { useEffect, useState } from "react";
import { loader } from "react-global-loader";
import { Icon } from "@iconify/react";
import AddCities from "./components/addCities";
import { Button } from "../../../components/ui/button";
import Common from "../../../helper/common";
import AdvanceTable from "../../../components/advanceTable";

const Cities = () => {
  const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [refId, setRefId] = useState(null);

    useEffect(() => {
        // loader.hide();
        getData();
    }, []);

    useEffect(() => {
        if (!open && refId) {
            setRefId();
        }
    }, [open]);

    const getData = async () => {
    setLoading(true);
        const response = await Common.ApiService.getInstance().request("GetCities");
        if (response?.data) {
            setData(response?.data);
            setLoading(false);
        }
  };

    const _handleDelete = async (id) => {
        const isConfirmed = await Common.SweetAlert.confirmation(
            "Are you sure you want to delete this city?",
            "Delete City"
        );
        if (isConfirmed) {
            let resp = await Common.ApiService.getInstance().request(
                `DeleteCity?id=${id}`,
                {},
                "Delete"
            );
            Common.SweetAlert.alert(resp.message, "success");
            setTimeout(() => {
                getData(1);
            }, 500);
        }
    };


  const _addUpdateTrigger = () => (
    <Button size="icon" variant="outline" color="secondary" className="h-7 w-7">
      <Icon icon="heroicons:pencil" className="h-4 w-4" />
    </Button>
  );

    const _onEdit = async (id) => {
        setOpen(true);
        setRefId(id);
    };

    const actions = [
        {
            type: "edit",
            onClick: (val) => _onEdit(val.id),
        },
        {
            type: "delete",
            onClick: (val) => _handleDelete(val.id),
        },

    ];

    const _thead = [
        { title: "Id", type: "number", isVisible: false, key: "id" },
        { title: "City Name", type: "text", isVisible: true, key: "name" },
        { title: "State", type: "text", isVisible: true, key: "stateName" },
        { title: "Country", type: "text", isVisible: true, key: "countryName" },
    ];

  if (loading) {
    return <div>Loading...</div>;
  }

    return (
        <>
            <AdvanceTable
                title={"Cities"}
                columns={_thead.filter((item) => item.isVisible)}
                data={{ data: data }}
                getData={getData}
                addButton={() => (
                    <AddCities
                        id={refId}
                        onClose={() => getData()}
                        open={open}
                        setOpen={setOpen}
                    />
                )}
                showPagination={false}
                showAddButton
                actionButtons={actions}
                updateTrigger={_addUpdateTrigger} 
            />
        </>
    );
};

export default Cities;
