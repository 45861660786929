import { RoleEnum, TransactionLogStatusEnum } from "../constants/appConstant";
import common from "../helper/common";
import {
    DashBoard,
    Calendar,
    User,
    Map,
    Application,
    PretentionChartLine2,
    Settings,
    Graph,
    Stacks2,
    Chart,
    BarLeft,
    Medical,
    Note3,
    Pointer,
} from "../svg";

const getRoleId = () => common.getUser()?.roleId;
export const getMenuConfig = (roleId) =>
    roleId == RoleEnum.Club_Manager
        ? {
            sidebarNav: {
                classic: [
                    {
                        title: "dashboard",
                        icon: Application,
                        href: "/app/dashboard",
                    },

                    {
                        isHeader: true,
                        title: "Booking",
                    },

                    {
                        title: "Bookings",
                        icon: Calendar,
                        href: "/app/clubbookings",
                        isOpen: false,
                        isHide: false,
                        child: [
                            {
                                title: "Booking List",
                                href: "/app/clubbookings",
                                icon: Graph,
                            },
                            {
                                title: "Booking Calendar",
                                href: "/app/bookingcalendar",
                                icon: Graph,
                            },
                            {
                                title: "Calendar",
                                href: "/app/calendar",
                                icon: Graph,
                            }
                        ],
                    },

                    {
                        title: "Customers",
                        icon: User,
                        href: "/app/clubcustomers",
                    },

                    {
                        isHeader: true,
                        title: "Setup",
                    },

                    {
                        title: "Courts",
                        icon: Map,
                        href: `/app/courts`,
                    },


                    //{
                    //    title: "Clubs ",
                    //    icon: BarLeft,
                    //    href: "/app/Clubs",
                    //    isOpen: false,
                    //    isHide: false,
                    //    child: [

                    //        {
                    //            title: "Club Setup",
                    //            href: "/app/clubsetup",
                    //            icon: Settings,
                    //        },
                    //        {
                    //            title: "Courts",
                    //            icon: Map,
                    //            href: `/app/courts`,
                    //        },
                    //        {
                    //            title: "Promo Code",
                    //            icon: Chart,
                    //            href: "/app/promocodes",
                    //        },
                    //    ],
                    //},
                    {
                        title: "Club Setup",
                        icon: Settings,
                        href: "/app/clubsetup",
                    },

                    {
                        title: "Promo Code",
                        icon: Chart,
                        href: "/app/promocodes",
                    },

                    {
                        title: "Holidays",
                        icon: Medical,
                        href: "/app/clubholidays",
                        isOpen: false,
                        isHide: false,
                        child: [
                            {
                                title: "Club Holidays",
                                icon: Medical,
                                href: "/app/clubholidays",
                            },
                            {
                                title: "Court Holidays",
                                icon: Medical,
                                href: "/app/courHolidays",
                            },
                        ],
                    },

               
                    // {
                    //   title: "Add Court Holiday",
                    //   icon: DashBoard,
                    //   href: "/courts/addholiday",
                    // },

                    {
                        isHeader: true,
                        title: "Accounts",
                    },

                    {
                        title: "Payments",
                        icon: PretentionChartLine2,
                        href: "#",
                        isOpen: false,
                        isHide: false,
                        child: [
                            {
                                title: "Received",
                                icon: DashBoard,
                                href: `/app/transactions?status=${TransactionLogStatusEnum.Success}`,
                            },
                            {
                                title: "Refundable",
                                icon: DashBoard,
                                href: `/app/transactions?status=${TransactionLogStatusEnum.Refundable}`,
                            },
                            {
                                title: "Refunded",
                                icon: DashBoard,
                                href: `/app/transactions?status=${TransactionLogStatusEnum.Refunded}`,
                            },
                            {
                                title: "Transaction Logs",
                                icon: DashBoard,
                                href: `/app/transactionLogs`,
                            },
                        ],
                    },
                    {
                        title: "Balance",
                        icon: Graph,
                        href: "/app/balance",
                    },
                    {
                        title: "Account Types",
                        icon: Stacks2,
                        href: "/app/clubAccounts",
                    },

                    {
                        title: "Receive/Refund Payment",
                        icon: Note3,
                        href: "/app/payments",
                    },

                    {
                        title: "Booking Adjustments",
                        icon: Pointer,
                        href: "/app/adjustments",
                    },

                    // {
                    //   isHeader: true,a
                    //   title: "Setup",
                    // },
                    // {
                    //   title: "Club Setup",
                    //   icon: Settings,
                    //   href: "/app/clubSetup",
                    // },
                ],
            },
        }
        : {
            sidebarNav: {
                classic: [
                    {
                        title: "Clubs",
                        icon: DashBoard,
                        href: "/app/Clubs",
                        isOpen: false,
                        isHide: false,
                        child: [
                            {
                                title: "Club List",
                                href: "/app/clubs",
                                icon: Graph,
                            },
                            //{
                            //    title: "Promo Code",
                            //    icon: Chart,
                            //    href: "/app/promocodes",
                            //},
                        ],
                    },

                    {
                        title: "Bookings",
                        icon: DashBoard,
                        href: "/app/clubbookings",
                        isOpen: false,
                        isHide: false,
                        child: [
                            {
                                title: "Booking List",
                                href: "/app/clubbookings",
                                icon: Graph,
                            },
                            // {
                            //   title: "Create Booking",
                            //   href: "/bookings/create",
                            //   icon: Cart,
                            // },
                        ],
                    },

                    {
                        title: "Tournament",
                        icon: DashBoard,
                        href: "#",
                        isOpen: false,
                        isHide: false,
                        child: [
                            {
                                title: "Tournament List",
                                icon: DashBoard,
                                href: "/app/tournaments",
                            },

                            {
                                title: "Grouping",
                                icon: DashBoard,
                                href: "/app/tournamentGrouping",
                            },
                            {
                                title: "Scheduling",
                                icon: DashBoard,
                                href: "/app/tournamentScheduling",
                            },
                        ],
                    },

                    {
                        isHeader: true,
                        title: "Set up",
                    },

                    {
                        title: "Settings",
                        icon: DashBoard,
                        href: "#",
                        isOpen: false,
                        isHide: false,
                        child: [
                            {
                                title: "Promo Code",
                                icon: Chart,
                                href: "/app/promocodes",
                            },
                            {
                                title: "Sports",
                                icon: DashBoard,
                                href: "/app/sports",
                            },

                            {
                                title: "Facilities",
                                icon: DashBoard,
                                href: "/app/facilities",
                            },

                            {
                                title: "Level Threshold",
                                icon: DashBoard,
                                href: "/app/levelthreshold",
                            },

                            {
                                title: "Countries",
                                icon: DashBoard,
                                href: "/app/countries",
                            },

                            {
                                title: "States",
                                icon: DashBoard,
                                href: "/app/states",
                            },

                            {
                                title: "Cities",
                                icon: DashBoard,
                                href: "/app/cities",
                            },
                        ],
                    },
                ],
            },
        };

export const menusConfig = getMenuConfig(getRoleId());
