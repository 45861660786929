import { Formik } from "formik";
import * as yup from "yup";
import { useEffect, useRef, useState } from "react";
import { Button } from "../../../components/ui/button";
import { respEnum, IntMinLevel } from "../../../constants/appConstant";
import {
    ErrorText,
    FormikInput,
    FormikSearchSelect,
    FormikTextArea,
    FormikSwitch
} from "../../../components/formik-components";
import { Icon } from "@iconify/react";
import { Card, CardContent } from "../../../components/ui/card";
import Common from "../../../helper/common";
import { useLocation, useNavigate } from "react-router-dom";

const formValidations = yup.object({
    question: yup.string().min(5, "Min 5 letter question").required("Question is required"),
    groupId: yup.string().min(IntMinLevel).max(20).required("Group Id is required"),
    options: yup.array().required().min(2, "Add at least 2 options"),
});

const optionFormValidations = yup.object({
    option: yup.string().required(),
    points: yup.number().min(IntMinLevel).max(1000).required(),
});
const formInitialValue = {
    sportId: "",
    question: "",
    groupId: "",
    isactive:true,
    options: [],
    //options: [
    //    {
    //        option: "Option 4",
    //        points: 200,
    //    },
    //    {
    //        option: "Option 5",
    //        points: 200,
    //    },
    //    {
    //        option: "Option 6",
    //        points: 200,
    //    },
    //    {
    //        option: "Option 7",
    //        points: 200,
    //    },
    //],

};

const optionInitialValues = {
    option: "",
    points: "",
};


export default function AddQuestion({ onRequestClose }) {
    const [sports, setSports] = useState([]);
    //var [questions, setQuestions] = useState([
    //    {
    //        question: "Question 1",
    //        groupId: 1,
    //        isactive: true,
    //        options: [
    //            {
    //                option: "Option 1",
    //                points: 200,
    //            },
    //            {
    //                option: "Option 2",
    //                points: 200,
    //            },
    //            {
    //                option: "Option 3",
    //                points: 300,
    //            },
    //            {
    //                option: "Option 4",
    //                points: 400,
    //            },
    //        ],
    //    },
    //    {
    //        question: "Question 2",
    //        groupId: 1,
    //        isactive: true,
    //        options: [
    //            {
    //                option: "Option A1",
    //                points: 200,
    //            },
    //            {
    //                option: "Option A2",
    //                points: 200,
    //            },
    //            {
    //                option: "Option A3",
    //                points: 300,
    //            },
    //            {
    //                option: "Option A4",
    //                points: 400,
    //            },
    //        ],
    //    },
    //]);
    var [questions, setQuestions] = useState([]);
    const [editingOptionIndex, setEditingOptionIndex] = useState(null);
    const [editingQueIndex, setEditingQueIndex] = useState(null);
    const [id, setId] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const formikRef = useRef();
    const optionFormikRef = useRef();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        var fetchedId = params.get("sportid");
        if (fetchedId) {
            setId(fetchedId);
            GetQuestionDetails(fetchedId);
        }
        getSports(fetchedId);
    }, []);

    //useEffect(() => {  }, []);


    const onSubmit = async (d,action) => {
        if (!d.values.sportId) {
            Common.showToast("Sports is Required");
            return;
        }

        if (questions.length <= 0) {
            Common.showToast("Their should be atleast One question.");
            return;
        }

        questions = questions.map((q) => ({
            ...q,
            sportId: d.values.sportId, 
        }));

        let data = {
            sportId: id != null ? id : 0,
            questions,
        };

        
        let response = await Common.ApiService.getInstance().request(
            "AddUpdateQuestion",
            data,
            "POST"
        );

        //action.setSubmitting(false);
        if (response.status == respEnum.Success) {
            Common.SweetAlert.alert(response.message);
            setTimeout(() => {
                navigate("/app/sports");
            }, 1000);
            
        }
    };


    const GetQuestionDetails = async (id) => {
        let response = await Common.ApiService.getInstance().request(
            `GetSportsQuestions?sportId=${id}`
        );
        let d = response?.data?.[0];
        if (d && formikRef.current) {
            formikRef.current.setFieldValue("sportId", d.sportId);
            setQuestions(d.questions);
        }
        else if(!d) {
            Common.showToast("Sport not Found");
        }
    };


    const getSports = async (id) => {
        const hasquestion = !Boolean(id);
        return new Promise(async (res) => {
            const response = await Common.ApiService.getInstance().request(
                `GetSportddl?hasquestions=${hasquestion}`
            );

            const data = [
                { id: null, name: "Select Sport" },
                ...(response?.data ?? []),
            ];

            setSports(data);
        });
    };

    //Delete option
    const handleDeleteOption = (index) => {
        let formikProps = formikRef.current;
        let options = formikProps.values.options;
        if (editingOptionIndex === index) {
            setEditingOptionIndex(null); 
            options[index].id = 0;
        }
        formikProps.setFieldValue("options", options.filter((_, i) => i !== index));
    };

    // Delete question
    const handleDeleteQuestion = (index) => {
        if (editingQueIndex === index) {
            setEditingQueIndex(null);
            formikRef.current.values.id = 0;
        }
        const updatedQuestions = questions.filter((_, i) => i !== index); 
        setQuestions(updatedQuestions); 
    };

    const handleEditOption = (index) => {
        setEditingOptionIndex(index);
        const optionToEdit = formikRef.current.values.options[index];
        optionFormikRef.current.setValues(optionToEdit);
    };

    const handleEditQuestion = (index) => {
        setEditingQueIndex(index); 
        const questionToEdit = questions[index];
        const formikProps = formikRef.current;
        formikProps.setValues(questionToEdit);
    };

    const onSubmitData = async (values, { setFieldValue }) => {
        if (
            questions.some(
                (item, index) =>
                    index !== editingQueIndex && 
                    item.question.toLowerCase() === values.question.toLowerCase()
            )
        ) {
            Common.showToast("Can't add the same question");
        } else {
            if (editingQueIndex !== null) {
                const updatedQuestions = [...questions];
                updatedQuestions[editingQueIndex] = values;
                setQuestions(updatedQuestions);
                setEditingQueIndex(null); 
            } else {
                setQuestions([...questions, values]);
            }
            
            setFieldValue("isactive", true);
            setFieldValue("question", "");
            setFieldValue("groupId", "");
            setFieldValue("options", []);
        }
    };

    
    const _onOptionSubmit = (values, { setFieldError, setFieldValue }) => {
        let formikProps = formikRef.current;
        let options = formikProps.values.options;

        if (
            options.some(
                (item, index) =>
                    index !== editingOptionIndex && 
                    item.option.toLowerCase() === values.option.toLowerCase()
            )
        ) {
            setFieldError("option", "Cannot add the same option");
        } else {
            if (editingOptionIndex !== null) {
                const updatedOptions = [...options];
                updatedOptions[editingOptionIndex] = values;

                formikProps.setFieldValue("options", updatedOptions);
                setEditingOptionIndex(null); 
            } else {
                formikProps.setFieldValue("options", [...options, values]); 
            }

            Object.keys(values).forEach((key) =>
                setFieldValue(key, optionInitialValues[key])
            );
        }
    };

    const Heading = ({ text }) => (
        <h6 class="text-lg font-bold dark:text-white">{text}</h6>
    );

    const EditAction = ({ onClick }) => (
        <Button
            size="icon"
            variant="soft"
            className="rounded-full"
            onClick={onClick}
        >
            <Icon icon="ic:outline-edit" className=" h-6 w-6 " />
        </Button>
    );

    const DeleteAction = ({ onClick }) => (
        <Button
            size="icon"
            variant="soft"
            color="destructive"
            className="rounded-full ml-2"
            onClick={onClick}
        >
            <Icon icon="ic:outline-delete" className=" h-6 w-6 " />
        </Button>
    );


    const Option = ({ item, showActions = true, onEdit, onDelete }) => (
        <div className="flex flex-row justify-between items-center">
            <div className="bg-primary-200 w-full flex flex-row justify-between rounded-md p-2 mr-3">
                <span className="text-primary">{item.option}</span>
                <span className="text-primary font-semibold">{item.points}</span>
            </div>
            {showActions && (
                <div className="flex flex-row">
                    <EditAction onClick={onEdit} />
                    <DeleteAction onClick={onDelete} />
                </div>
            )}
        </div>
    );

    const _renderQuestion = (item, index) => (
        <div className="border-b-2 pb-5">
            <div className="flex flex-row justify-between">
                <span className="font-bold text-wrap w-full">
                    {item.question} - (Group: {item.groupId})
                </span>
                <div className="flex flex-row justify-end w-[62.333333%] space-x-3">
                    <span
                        className={item.isactive ? "text-green-500" : "text-red-500"} style={{ marginTop: '9px' }}
                    >
                        {item.isactive ? "Active" : "Not Active"}
                    </span>
                    <EditAction onClick={() => handleEditQuestion(index)} />
                    <DeleteAction onClick={() => handleDeleteQuestion(index)} />
                </div>
            </div>
            <div className="pt-3">
                {item.options.map((option) => (
                    <Option key={option.option} item={option} showActions={false} />
                ))}
            </div>
        </div>
    );


    return (
        <Card className="p-3">
            <CardContent>
                <Formik
                    initialValues={formInitialValue}
                    validationSchema={formValidations}
                    innerRef={formikRef}
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={onSubmitData}
                >
                    {(formikProps) => (
                        <div className="md:grid md:grid-cols-3 mb-10 gap-2 space-y-3 md:space-y-0">
                            <div className="col-span-1 border-r-2 pr-5">
                                <div className="md:grid md:grid-cols-1  gap-2  space-y-3 md:space-y-0">
                                    <div className="flex flex-col gap-3">
                                        <FormikSearchSelect
                                            formikProps={formikProps}
                                            name={"sportId"}
                                            data={sports}
                                            placeholder={"Select Sport"}
                                            label={"Select Sport"}
                                        />
                                    </div>

                                    <div className="flex flex-col gap-3">
                                        <FormikTextArea
                                            formikProps={formikProps}
                                            name={"question"}
                                            placeholder={"Enter Question"}
                                            label={"Question"}
                                            maxLength="100"
                                        />
                                    </div>

                                    <div className="flex flex-row items-center gap-4 mb-3">
                                        <div className="flex flex-col flex-1">
                                        <FormikInput
                                            formikProps={formikProps}
                                            name={"groupId"}
                                            placeholder={"Enter Group Id"}
                                            label={"Group Id"}
                                            type={"number"}
                                            max={30}
                                            min={IntMinLevel}
                                            onChange={(value) => {
                                                if (value < IntMinLevel && value != "") {
                                                    formikProps.setFieldValue("groupId", IntMinLevel);
                                                } else if (value > 30) {
                                                    Common.showToast(`groupId cannot be greater than 30`);
                                                    formikProps.setFieldValue("groupId", "");
                                                } else {
                                                    formikProps.setFieldValue("groupId", value);
                                                }
                                            }}
                                        />
                                    </div>

                                    <div className="flex flex-col col-span-1 gap-2">
                                        <FormikSwitch
                                            formikProps={formikProps}
                                            name={"isactive"}
                                            label={"Is Active"}
                                            style={{ marginTop: '7px' }}
                                        />
                                        </div>
                                    </div>

                                    <Heading text={"Add Option"} />
                                    <Formik
                                        initialValues={{}}
                                        innerRef={optionFormikRef}
                                        validationSchema={optionFormValidations}
                                        validateOnBlur={false}
                                        validateOnChange={false}
                                        onSubmit={_onOptionSubmit}
                                    >
                                        {(optionFormikProps) => (
                                            <>
                                                <div className="flex flex-col gap-3">
                                                    <FormikInput
                                                        formikProps={optionFormikProps}
                                                        name={"option"}
                                                        placeholder={"Enter Option"}
                                                        label={"Option"}
                                                        maxLength="50"
                                                    />
                                                </div>

                                                <div className="flex flex-col gap-3">
                                                    <FormikInput
                                                        formikProps={optionFormikProps}
                                                        name={"points"}
                                                        placeholder={"Enter Option Points (Max 1000)"}
                                                        label={"Option Points"}
                                                        type={"number"}
                                                        max={1000}
                                                        min={IntMinLevel}
                                                        onChange={(value) => {
                                                            if (value < IntMinLevel && value != "") {
                                                                optionFormikProps.setFieldValue("points", IntMinLevel);
                                                            } else if (value > 1000) {
                                                                Common.showToast(`points cannot be greater than 1000`);
                                                                optionFormikProps.setFieldValue("points", "");
                                                            } else {
                                                                optionFormikProps.setFieldValue("points", value);
                                                            }
                                                        }}
                                                    />
                                                </div>

                                                <div className="mt-3 flex justify-end">
                                                    <Button
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            optionFormikProps.handleSubmit();
                                                        }}
                                                        disabled={formikProps.isSubmitting}
                                                        type="button"
                                                        className="p-3 mt-3"
                                                    >
                                                        {editingOptionIndex !== null ? "Update Option" : "Add Option"}
                                                    </Button>
                                                </div>

                                            </>
                                        )}
                                    </Formik>

                                    {formikProps.values.options.length > 0 && (
                                        <Heading text={"Options"} />
                                    )}

                                    {formikProps.values.options.map((item, index) => (
                                        <Option
                                            key={item.option}
                                            item={item}
                                            showActions={true}
                                            onEdit={() => handleEditOption(index)} 
                                            onDelete={() => handleDeleteOption(index)} 
                                        />
                                    ))}


                                    {formikProps.errors.options && (
                                        <ErrorText formikProps={formikProps} name={"options"} />
                                    )}

                                    <div className="mt-5 w-full">
                                        <Button
                                            className="mt-5 w-full"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                formikProps.handleSubmit();
                                            }}
                                            disabled={formikProps.isSubmitting}
                                            type="button"
                                        >
                                            {editingQueIndex !== null ? "Update Question" : "Add Question"}
                                        </Button>
                                    </div>

                                </div>
                            </div>
                            <div className="col-span-2 pl-5">
                                <div className="md:grid md:grid-cols-1 gap-2 space-y-3 md:space-y-0">
                                    <Heading text={"Questions"} />

                                    {questions.map(_renderQuestion)}

                                    
                                </div>
                                <div className="flex flex-col items-end mt-5">
                                    <Button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            onSubmit(formikProps);
                                            //formikProps.handleSubmit();
                                        }}
                                        disabled={formikProps.isSubmitting}
                                        type="submit"
                                        className="mt-7 btn-sm"
                                    >
                                        {formikProps.isSubmitting ? "Saving..." : "Save"}
                                    </Button>

                                </div>
                            </div>

                           
                        </div>


                    )}
                </Formik>
            </CardContent>
        </Card>
    );
}
