import { Formik } from "formik";
import * as yup from "yup";
import toast from "react-hot-toast";
import { useCallback, useEffect, useRef, useState } from "react";
import { Button } from "../../../components/ui/button";
import {
  FormikInput,
  FormikSelect,
  FormikSearchSelect,
  FormikDate,
  FormikSearchSectionSelect,
} from "../../../components/formik-components";
import Common from "../../../helper/common";

import {
  IntCourtPriceMin,
  IntMax,
  IntMin,
  PaymentStatusEnum,
  PaymentTypeEnum,
  ResultStatus,
  ToCSharpFormat,
  DateminFormat,
  respEnum,
  SlotStatusEnum,
} from "../../../constants/appConstant";

import { useNavigate } from "react-router-dom";
import AddCustomer from "../../clubCustomers/components/addCustomer";
import animationData from "../../../assets/json/lotties/noData.json";
import common from "../../../helper/common";

const formValidations = yup.object({});
const formInitialValue = {};

export default function AddPromoCode({ onRequestClose }) {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const formikRef = useRef();

  const onSubmitData = async (d, action) => {
    let data = {
      ...d,
    };
  };

  return (
    <Formik
      initialValues={formInitialValue}
      validationSchema={formValidations}
      innerRef={formikRef}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmitData}
    >
      {(formikProps) => (
        <div className="md:grid md:grid-cols-3 mb-10 gap-2 space-y-3 md:space-y-0">
          <div className="col-span-1">
            <div className="md:grid md:grid-cols-1  gap-2  space-y-3 md:space-y-0">
              <div className="flex flex-col gap-3">
                <FormikInput
                  formikProps={formikProps}
                  name={"promoCode"}
                  placeholder={"Enter Promo Code"}
                  label={"Promo Code"}
                />
              </div>

              <div className="md:grid">
                <div className="md:grid md:grid-cols-2  gap-6 space-y-3 md:space-y-0">
                  <div className="flex flex-col gap-3">
                    <FormikInput
                      formikProps={formikProps}
                      name={"value"}
                      placeholder={"Enter Value"}
                      label={"Value"}
                      type={"number"}
                    />
                  </div>

                  <div className="flex flex-col gap-3">
                    <FormikSelect
                      formikProps={formikProps}
                      name={"valueType"}
                      placeholder={"Select Value Type"}
                      label={"Value Type"}
                      data={[
                        { id: 2, name: "%" },
                        { id: 4, name: "value" },
                      ]}
                    />
                  </div>
                </div>
              </div>

              <div className="md:grid">
                <div className="md:grid md:grid-cols-2  gap-6 space-y-3 md:space-y-0">
                  <div className="flex flex-col gap-3">
                    <FormikDate
                      formikProps={formikProps}
                      name={"startDate"}
                      type={"date"}
                      label={"Valid From"}
                      min={Common.Utility.today}
                      onChange={(val) => {
                          Common.Utility.checkPreviousDate(val, "Start Date");
                      }}
                    />
                  </div>

                  <div className="flex flex-col gap-3">
                    <FormikDate
                      formikProps={formikProps}
                      name={"endDate"}
                      type={"date"}
                      label={"Valid Till"}
                      min={Common.Utility.today}
                      onChange={(val) => {
                        Common.Utility.checkPreviousDate(val, "End Date");
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-5">
                <Button
                  className="mt-5"
                  onClick={(e) => {
                    e.preventDefault();
                    formikProps.handleSubmit();
                  }}
                  disabled={formikProps.isSubmitting}
                  type="button"
                >
                  {"Submit"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
}
