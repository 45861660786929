import React, { useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import Common from "../../helper/common";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import {
    FormikInput,
    FormikTextArea,
    FormikUploaderMultipleCarousel,
    FormikDate,
    FormikSwitch,
    FormikSelect,
} from "../../components/formik-components";
import { Button } from "../../components/ui/button";
import { Card, CardContent } from "../../components/ui/card";
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "../../components/ui/tabs";
import { Icon } from "@iconify/react";
import TimingsCard from "../../components/cards/timingsCard";
import FacilitiesCard from "../../components/cards/facilitiesCard";
import { DayOfWeekEnum } from "../../constants/appConstant";
import { Label } from "../../components/ui/label";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { respEnum, IntMax, IntMinLevel, ClubDisplayTypeEnum, MatchTypeEnum, DateminFormat } from "../../constants/appConstant";
import GoogleMapCustom from "../../components/ui/CustomMap";

const formValidations = yup.object({
    name: yup.string().required('Club Name is required'),
    websiteUrl: yup.string().url('Enter a valid URL').required('Website URL is required'),
    phone: yup.string().required('Phone number is required'),
    cancellationHours: yup.number().required('Cancellation policy is required'),
    countryName: yup.string().required('Country is required'),
    stateName: yup.string().required('State is required'),
    cityName: yup.string().required('City is required'),
    address: yup.string().required('Address is required'),
    postalCode: yup.number().required('Postal Code is required'),
    lat: yup.number()
    .typeError('Latitude must be a number')
    .required('Latitude is required'),
    lng: yup.number()
    .typeError('Longitude must be a number')
    .required('Longitude is required'),
    startFrom: yup.number().required('Start From is required').min(IntMinLevel).max(IntMax),

});

const formInitialValues = {};

export default function ClubSettings() {
    const navigate = useNavigate();
    const formikRef = useRef();
    // const [position, setPosition] = useState({ lat: 31.4670196, lng: 74.3765426 });
    const [position, setPosition] = useState({ lat: null, lng: null });
    const [isLoading, setIsLoading] = useState(true);
    const [State, setState] = useState([]);
    const [Country, setCountry] = useState([]);
    const [City, setCity] = useState([]);
    var [facilities, setFacilities] = useState([]);
    const [clubImages, setClubImages] = useState([]);
    const [allFacilities, setAllFacilities] = useState([]);
    const [pinLocationName, setpinLocationName] = useState("");

    const [timings, setTimings] = useState(
        Common.Utility.enumToArray(DayOfWeekEnum).map((day) => ({
            ...day,
            isOn: false,
            dayOfweek: day.id,
            openingTime: "",
            closingTime: "",
        }))
    );

    useEffect(() => {
        const fetchInitialData = async () => {

            const allFacility = await GetFacilities();
            setAllFacilities(allFacility);
            await Common.delay();
            GetClubDetails(allFacility);
        };
        fetchInitialData();
    }, []);

    const GetFacilities = async () => {
        const response = await Common.ApiService.getInstance().request("GetMasterFacilities");
        const data = response?.data?.map((facility) => ({
            ...facility,
            isSelected: false,
        })) ?? [];
        setFacilities(data);
        return data;
    };

    const GetClubDetails = async (allFacility) => {
        setIsLoading(true);
        const response = await Common.ApiService.getInstance().request("GetMyClubDetails");
        const d = response?.data?.[0];
        setpinLocationName(d.pinLocationName);
        if (d && d.facilities) {
            const updatedFacilities = allFacility.map((facility) => ({
                ...facility,
                isSelected: d.facilities.some((cf) => cf.masterFacilityId == facility.id)
            }));
            setFacilities(updatedFacilities);
        }
    
        setClubImages(d.images ?? []);
        const clubTimings = d.timings ?? [];
        const formattedTimings = Common.Utility.enumToArray(DayOfWeekEnum).map((day) => {
            const timing = clubTimings.find((t) => t.dayOfweek === day.id) || {
                isOn: false,
                dayOfweek: day.id,
                openingTime: "",
                closingTime: "",
            };
    
            const formatTime = (time) => {
                if (!time) return "";
                const [hours, minutes] = time.split(":");
                return `${hours}:${minutes}`;
            };
    
            return {
                ...day,
                ...timing,
                openingTime: formatTime(timing.openingTime),
                closingTime: formatTime(timing.closingTime),
                id: day.id,
            };
        });
    
        setTimings(formattedTimings);
    
        if (d.lat && d.lng) {
            const lat = parseFloat(d.lat);
            const lng = parseFloat(d.lng);
            
            setPosition({ lat, lng });

            if (!isNaN(lat) && !isNaN(lng)) {
                setPosition({ lat, lng });
            }
    
            if (formikRef.current) {
                formikRef.current.setFieldValue('lat', lat);
                formikRef.current.setFieldValue('lng', lng);
            }
        }
        if (d.maxBookingHours)
            d.maxBookingHours = d.maxBookingHours / 24

            debugger
        if (d.bookingStartFrom) 
            d.bookingStartFrom = moment(d.bookingStartFrom).format(DateminFormat);

        if (d.bookingTill) 
            d.bookingTill = moment(d.bookingTill).format(DateminFormat);
       
        if (formikRef.current) {
            formikRef.current.setValues(d);
        }
        setIsLoading(false);
    };

    const getCities = async () => {
        return new Promise(async (res) => {
            const response = await Common.ApiService.getInstance().request(
                "GetCities"
            );
            const data = [
                { id: null, name: "Select City" },
                ...(response?.data ?? []),
            ];

            setCity(data);
        });
    };

    const getCountries = async () => {
        return new Promise(async (res) => {
            const response = await Common.ApiService.getInstance().request(
                "GetCountries"
            );
            const data = [
                { id: null, name: "Select Country" },
                ...(response?.data ?? []),
            ];

            setCountry(data);
        });
    };

    const getStates = async (countryId) => {
        return new Promise(async (res) => {
            const response = await Common.ApiService.getInstance().request(
                `GetStates?countryId=${countryId}`
            );
            const data = [
                { id: null, name: "Select State" },
                ...(response?.data ?? []),
            ];

            setState(data);
        });
    };

    const _onSubmit = async (d, action) => {
        debugger
        let data = {
            ...d,
            lat: d.lat ? String(d.lat) : "", 
            lng: d.lng ? String(d.lng) : "",
            minimumBookingHours: d.minimumBookingHours ? +d.minimumBookingHours : null,
            maxBookingHours: d.maxBookingHours ? d.maxBookingHours * 24 : null, 
            startFrom: +d.startFrom,
            displayType: d.displayType ? d.displayType : null,
            skipMidTime: d.skipMidTime ? d.skipMidTime : false,
            bookingStartFrom: d.bookingStartFrom ? d.bookingStartFrom : null,
            bookingTill: d.bookingTill ? d.bookingTill : null,
            defaultMatchType: d.defaultMatchType ? d.defaultMatchType : null,
        };

        if (!data.facilities.length) {
            Common.showToast("Add at least one facility", "Error");
            return;
        }

        data.facilities = data.facilities.map((facility) => ({
            masterFacilityId: facility.id,       
            masterFacilityName: facility.name,  
        }));
        

        if (d.files && d.files.filter((item) => !item.imageUrl).length) {
            let uploadedFiles = [];
            for (let i = 0; i < d.files.length; i++) {
                let file = d.files[i];
                if (!file.imageUrl) {
                    let uploadedFile = await Common.Utility.handleFileUpload([file]);
                    uploadedFiles.push(uploadedFile[0].photo);
                }
            }


            if (uploadedFiles.length) {
                const newImages = uploadedFiles.map((file) => ({
                    id: 0,
                    path: file,
                }));

                data.images = [...clubImages, ...newImages];
            }

            data.files = null;
        }
        else { data.images = [...clubImages]; }

        if (!data.images.length) {
            Common.showToast("Add at least one Image", "Error");
            return;
        }

        let response = await Common.ApiService.getInstance().request(
            "UpdateClub",
            data,
            "PATCH"
        );
        action.setSubmitting(false);
        if (response.status == respEnum.Success) {
            Common.SweetAlert.alert(response.message);
            setTimeout(() => {
                navigate("/app/clubsetup");
            }, 500);
        }

    };

    const _onSubmitTiming = async (d, action) => {
        let data = { ...d };

        if (data.timings?.length) {
            let invalidTime = data.timings.find((item) => {
                if (!item.isOn) return false;

                const isOpeningTimeValid = Common.Utility.CheckTime(item.openingTime);
                const isClosingTimeValid = Common.Utility.CheckTime(item.closingTime);
                const validTime = item.openingTime < item.closingTime;

                if (!item.openingTime || !item.closingTime || !isOpeningTimeValid || !isClosingTimeValid || !validTime) {
                    const dayName = Common.Utility.getKeyByValue(DayOfWeekEnum, item.dayOfweek);

                    if (!item.openingTime) {
                        Common.showToast(`Provide opening time for ${dayName}`, "Error");
                        return true;
                    }

                    if (!isOpeningTimeValid) {
                        Common.showToast(
                            `Invalid time format for opening time. Expected formats: 00, 30, or 23:59 for ${dayName}`,
                            "Error"
                        );
                        return true;
                    }

                    if (!item.closingTime) {
                        Common.showToast(`Provide closing time for ${dayName}`, "Error");
                        return true;
                    }

                    if (!isClosingTimeValid) {
                        Common.showToast(
                            `Invalid time format for closing time. Expected formats: 00, 30, or 23:59 for ${dayName}`,
                            "Error"
                        );
                        return true;
                    }

                    if (!validTime) {
                        Common.showToast(
                            `Closing time must be greater than opening time for ${dayName}`,
                            "Error"
                        );
                        return true;
                    }
                }
                return false;
            });

            if (invalidTime) return;
        }

        data.timings = data.timings.map((item) => ({
            ...item,
            openingTime: item.isOn
                ? Common.Utility.formatTimeToCSharp(item.openingTime)
                : null,
            closingTime: item.isOn
                ? Common.Utility.formatTimeToCSharp(item.closingTime)
                : null,
        }));

        let response = await Common.ApiService.getInstance().request(
            "UpdateClubTiming",
            data.timings,
            "PATCH"
        );
        action.setSubmitting(false);
        if (response.status == respEnum.Success) {
            Common.SweetAlert.alert(response.message);
            setTimeout(() => {
                navigate("/app/clubsetup");
            }, 500);
        }
    };

    const handleRemoveClubImage = (indexToRemove) => {
        const updatedImages = clubImages.filter((_, index) => index !== indexToRemove);
        setClubImages(updatedImages);
    };

    const handleTabChange = (tab) => {
        if (tab === "basic") 
            GetClubDetails(allFacilities); 
    };

    const handleLocationChange = (newPosition) => {
        setPosition(newPosition);
        if (formikRef.current) {
            // Explicitly convert to number to ensure proper type
            formikRef.current.setFieldValue("lat", Number(newPosition.lat));
            formikRef.current.setFieldValue("lng", Number(newPosition.lng));
            formikRef.current.setFieldValue("pinLocationName", newPosition.pinLocationName);
        }
    };

    const Heading = ({ text }) => (
        <h4 class="text-2xl font-bold dark:text-white mb-5">{text}</h4>
    );

    const _renderBasic = () => (
        <Formik
            initialValues={formInitialValues}
            validationSchema={formValidations}
            innerRef={formikRef}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={_onSubmit}
        >
            {(formikProps) => (
                <Card>
                    <CardContent className="p-3">
                        <div className="md:grid md:grid-cols-7 mb-10 gap-2 space-y-3 md:space-y-0">
                            <div className="col-span-3">
                                <Heading text={"Contact info"} />
                                <div className="md:grid md:grid-cols-1  gap-2  space-y-3 md:space-y-0">
                                    <div className="flex flex-col gap-2">
                                        <FormikInput
                                            formikProps={formikProps}
                                            name={"name"}
                                            placeholder={"Enter Club Name"}
                                            label={"Club Name"}
                                            disabled={true}
                                        />
                                    </div>
                                    <div className="flex flex-col gap-2">
                                        <FormikInput
                                            formikProps={formikProps}
                                            name={"websiteUrl"}
                                            placeholder={"Enter Website Url"}
                                            label={"Website Url"}
                                        />

                                    </div>

                                    <div className="flex flex-col gap-2">
                                        <FormikInput
                                            formikProps={formikProps}
                                            name={"phone"}
                                            placeholder={"Enter Phone Number"}
                                            label={"Phone Number"}
                                        />
                                    </div>

                                </div>
                            </div>
                            <div className="col-span-1 h-full flex justify-center">
                                <span className="border-r-2" />
                            </div>
                            <div className="col-span-3">
                                <Heading text={"About"} />
                                <div className="md:grid md:grid-cols-1  gap-2  space-y-3 md:space-y-0">
                                    <div className="flex flex-col gap-2">
                                        <FormikTextArea
                                            formikProps={formikProps}
                                            name={"desc"}
                                            placeholder={"Enter description"}
                                            label={"Description"}
                                            maxLength={500}
                                            rows={7}
                                        />
                                    </div>

                                </div>
                            </div>
                            <div className="col-span-7">
                                <div
                                    style={{
                                        height: 1,
                                        backgroundColor: "#D3D3D3",
                                        marginTop: 20,
                                        marginBottom: 20,
                                    }}
                                />
                            </div>

                            {/* Row 2 Starts Here */}
                            <div className="col-span-3">
                                <Heading text={"Facilities"} />
                                <div className="md:grid md:grid-cols-1 gap-2 space-y-3 md:space-y-0">
                                    <FacilitiesCard
                                        formikProps={formikProps}
                                        data={facilities}
                                        name={"facilities"}
                                        title={"Facilities"}
                                    />
                                </div>
                            </div>
                            <div className="col-span-1 h-full flex justify-center">
                                <span className="border-r-2" />
                            </div>

                            <div className="col-span-3">
                                <Heading text={"Bookings"} />
                                {/* Adjusted grid layout */}
                                <div className="md:grid md:grid-cols-3 gap-4 space-y-3 md:space-y-0">
                                    {/* Existing Fields */}
                                    <div className="flex flex-col gap-2">
                                        <FormikDate
                                            formikProps={formikProps}
                                            name={"bookingStartFrom"}
                                            type={"date"}
                                            placeholder={"Booking Start From"}
                                            label={"Booking Start From"}
                                        // min={Common.Utility.today}
                                        // onChange={(val) => {
                                        //     Common.Utility.checkPreviousDate(val, "bookingStartFrom");
                                        //  }}
                                        />
                                    </div>

                                    <div className="flex flex-col gap-2">
                                        <FormikDate
                                            formikProps={formikProps}
                                            name={"bookingTill"}
                                            type={"date"}
                                            placeholder={"Booking Till"}
                                            label={"Booking Till"}
                                        // min={Common.Utility.today}
                                        />
                                    </div>

                                    <div className="flex flex-col gap-2">
                                        <FormikInput
                                            formikProps={formikProps}
                                            name={"startFrom"}
                                            placeholder={"Start From"}
                                            label={"Start From"}
                                            min={IntMinLevel}
                                            max={IntMax}
                                            onChange={(value) => {
                                                if (value < IntMinLevel && value != "") {
                                                    formikProps.setFieldValue("startFrom", "");
                                                } else if (value > IntMax) {
                                                    Common.showToast(`Start From cannot be greater than ${IntMax}`);
                                                    formikProps.setFieldValue("startFrom", "");
                                                } else {
                                                    formikProps.setFieldValue("startFrom", value);
                                                }
                                            }}
                                        />
                                    </div>

                                    <div className="flex flex-col gap-2">
                                        <FormikInput
                                            formikProps={formikProps}
                                            name={"cancellationHours"}
                                            placeholder={"Cancellation policy (hours)"}
                                            label={"Cancellation Policy (hours)"}
                                        />
                                    </div>
                                    <div className="flex flex-col gap-2">
                                        <FormikInput
                                            formikProps={formikProps}
                                            name={"minimumBookingHours"}
                                            placeholder={"Min booking hours"}
                                            label={"Minimum Hours Before Booking"}
                                            type={"number"}
                                        />
                                    </div>
                                    <div className="flex flex-col gap-2">
                                        <FormikInput
                                            formikProps={formikProps}
                                            name={"maxBookingHours"}
                                            type={"number"}
                                            placeholder={"Max booking/day"}
                                            label={"Maximum Advance Days for Booking"}
                                        />
                                    </div>

                                    <div className="flex flex-col gap-2">
                                        <FormikSelect
                                            formikProps={formikProps}
                                            name="displayType"
                                            data={[
                                                { id: null, name: "Select" },
                                                ...Common.Utility.enumToArray(
                                                    ClubDisplayTypeEnum
                                                ),
                                            ]}
                                            placeholder={"Enter Display Type"}
                                            label={"Display Type"}
                                        />
                                    </div>
                                   
                                    <div className="flex flex-col gap-2">
                                        <FormikSelect
                                            formikProps={formikProps}
                                            name="defaultMatchType"
                                            data={[
                                                { id: null, name: "Select" },
                                                ...Common.Utility.enumToArray(
                                                    MatchTypeEnum
                                                ),
                                            ]}
                                            placeholder={"Default Match Type"}
                                            label={"Default Match Type"}
                                        />
                                    </div>

                                    <div className="flex flex-col gap-2">
                                        <FormikSwitch
                                            formikProps={formikProps}
                                            name={"skipMidTime"}
                                            label={"Skip Mid Time"}
                                            className="mt-2 ml-3"
                                        />
                                    </div>

                                </div>
                            </div>

                            <div className="col-span-7">
                                <div
                                    style={{
                                        height: 1,
                                        backgroundColor: "#D3D3D3",
                                        marginTop: 20,
                                        marginBottom: 20,
                                    }}
                                />
                            </div>

                            {/* Row 2 Starts Here */}
                            {/* Row 3 Starts Here */}
                            <div className="col-span-3">
                                <Heading text={"Location"} />
                                <div className="md:grid md:grid-cols-1  gap-2  space-y-3 md:space-y-0">
                                    <div className="flex flex-col gap-2">
                                        {/*<FormikSearchSelect*/}
                                        {/*    formikProps={formikProps}*/}
                                        {/*    name={"countryId"}*/}
                                        {/*    data={[{ name: "Pakistan", id: 1 }]}*/}
                                        {/*    placeholder={"Select Country"}*/}
                                        {/*    label={"Country"}*/}
                                        {/*    disabled*/}
                                        {/*/>*/}
                                        <FormikInput
                                            formikProps={formikProps}
                                            name={"countryName"}
                                            placeholder={"Country"}
                                            label={"Country"}
                                            disabled
                                        />

                                    </div>

                                    <div className="md:grid">
                                        <div className="md:grid md:grid-cols-2  gap-6 space-y-3 md:space-y-0">
                                            {/*<div className="flex flex-col gap-3">*/}
                                            {/*    <FormikSearchSelect*/}
                                            {/*        formikProps={formikProps}*/}
                                            {/*        name={"stateId"}*/}
                                            {/*        data={[{ name: "Sindh", id: 1 }]}*/}
                                            {/*        placeholder={"Select State"}*/}
                                            {/*        label={"State"}*/}
                                            {/*    />*/}
                                            {/*</div>*/}

                                            <FormikInput
                                                formikProps={formikProps}
                                                name={"stateName"}
                                                placeholder={"State"}
                                                label={"State"}
                                                disabled
                                            />

                                            {/*<div className="flex flex-col gap-3">*/}
                                            {/*    <FormikSearchSelect*/}
                                            {/*        formikProps={formikProps}*/}
                                            {/*        name={"cityId"}*/}
                                            {/*        data={[{ name: "Karachi", id: 1 }]}*/}
                                            {/*        placeholder={"Select City"}*/}
                                            {/*        label={"City"}*/}
                                            {/*    />*/}
                                            {/*</div>*/}

                                            <FormikInput
                                                formikProps={formikProps}
                                                name={"cityName"}
                                                placeholder={"City"}
                                                label={"City"}
                                                disabled
                                            />
                                        </div>
                                    </div>

                                    <div className="flex flex-col gap-2">
                                        <FormikTextArea
                                            formikProps={formikProps}
                                            name={"address"}
                                            placeholder={"Enter Address"}
                                            label={"Address"}
                                        />
                                    </div>
                                    <div className="flex flex-col gap-2">
                                        <FormikInput
                                            formikProps={formikProps}
                                            name={"postalCode"}
                                            placeholder={"Enter Postal Code"}
                                            label={"Postal Code"}
                                            type={"number"}
                                        />
                                    </div>
                                    <div>
                                        <h3 className="text-xl font-bold mb-4">Pick a Location</h3>
                                        {!isLoading && (
    <GoogleMapCustom
        defaultLat={position.lat}
                                                defaultLng={position.lng}
                                                pinLocationName={pinLocationName}
                                                onLocationChange={handleLocationChange}

    />
)}
                                    </div>

                                    <div className="md:grid ">
                                        <div className="md:grid md:grid-cols-2 gap-6 space-y-3 md:space-y-0 .hidden-important" style={{ display: "none" }} ref={(el) => { if (el) el.style.setProperty("display", "none", "important"); }}>
                                        <div className="flex flex-col gap-3"  >
                                                <FormikInput
                                                    formikProps={formikProps}
                                                    name={"lat"}
                                                    placeholder={"Enter Latitude"}
                                                    label={"Latitude"}
                                                    type={"number"}
                                                    disabled={true}
                                                />
                                            </div>

                                            <div className="flex flex-col gap-3">
                                                <FormikInput
                                                    formikProps={formikProps}
                                                    name={"lng"}
                                                    placeholder={"Enter Longitude"}
                                                    label={"Longitude"}
                                                    type={"number"}
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-span-1 h-full flex justify-center">
                                <span className="border-r-2" />
                            </div>
                            <div className="col-span-3">
                                <Heading text={"Images"} />
                                <FormikUploaderMultipleCarousel
                                    formikProps={formikProps}
                                    title="Drop Images here or click to upload"
                                    description="PNG, JPG, JPEG files only and maximum size of 1 MB each"
                                    maxFileUploadSize={1}
                                    renderImagetoState={clubImages}
                                    renderImage={true}

                                    /* onFilesUpdate={(updatedFiles) => setClubImages(updatedFiles)}*/

                                />

                                {clubImages.length > 0 && (
                                    <>
                                        <h3 className="text-lg font-semibold mt-4 mb-2">Current Images</h3>
                                        <Swiper
                                            navigation={true}
                                            modules={[Navigation]}
                                            slidesPerView={clubImages.length === 1 ? 1 : clubImages.length === 2 ? 2 : 3}
                                            spaceBetween={10}
                                            className="mySwiper mt-4"
                                        >
                                            {clubImages.map((image, index) => (
                                                <SwiperSlide key={index} className="relative">
                                                    <img
                                                        src={`${Common.AppConstant.imgBaseurl}${image.path}`}
                                                        alt={`Club ${index + 1}`}
                                                        className="w-full h-64 object-cover rounded-md"
                                                    />
                                                    <button
                                                        className="absolute top-2 right-2 bg-red-500 text-white p-2 rounded-full"
                                                        onClick={() => handleRemoveClubImage(index)}
                                                    >
                                                        <Icon icon="tabler:x" className="h-5 w-5" />
                                                    </button>
                                                </SwiperSlide>
                                            ))}
                                        </Swiper>
                                    </>
                                )}

                            </div>
                            <div className="col-span-7">
                                <div
                                    style={{
                                        height: 1,
                                        backgroundColor: "#D3D3D3",
                                        marginTop: 20,
                                        marginBottom: 20,
                                    }}
                                />
                            </div>
                            {/* Row 3 Ends Here */}
                        </div>
                        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "1rem" }}>
                            <Button
                                className="mt-5"
                                type={"submit"}
                                onClick={(e) => {
                                    e.preventDefault();
                                    formikProps.handleSubmit();
                                }}
                                disabled={formikProps.isSubmitting}
                            >
                                {formikProps.isSubmitting ? "Saving..." : "Save"}
                            </Button>

                        </div>
                    </CardContent>
                </Card>
            )}
        </Formik>
    );

    const _renderTimings = () => (
        <Formik
            initialValues={{ timings }}
            innerRef={formikRef}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={_onSubmitTiming}
        >
            {(formikProps) => (
                <>

                    <TimingsCard
                        formikProps={formikProps}
                        name={"timings"}
                        data={timings}
                        showCustomButton={true}
                    />
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginTop: "1rem",
                        }}
                    >
                        <Button
                            className="mt-5"
                            type={"submit"}
                            onClick={(e) => {
                                e.preventDefault();
                                formikProps.handleSubmit();
                            }}
                            disabled={formikProps.isSubmitting}
                        >
                            {formikProps.isSubmitting ? "Saving..." : "Save"}
                        </Button>

                    </div>
                </>
            )}
        </Formik>
    );

    //const _renderBooking = () => (
    //    <Formik
    //        initialValues={formInitialValues}
    //        validationSchema={formValidations}
    //        innerRef={formikRef}
    //        validateOnChange={false}
    //        validateOnBlur={false}
    //        onSubmit={_onSubmit}
    //    >
    //        {(formikProps) => (
    //            <Card>
    //                <CardContent className="p-3">
    //                    <div className="md:grid md:grid-cols-7 mb-10 gap-2 space-y-3 md:space-y-0">
    //                        <div className="col-span-3">
    //                            <Heading text={"Booking"} />
    //                            <div className="md:grid md:grid-cols-1  gap-2  space-y-3 md:space-y-0">
    //                                <div className="flex flex-col gap-2">
    //                                    <FormikInput
    //                                        formikProps={formikProps}
    //                                        name={"name"}
    //                                        placeholder={"Cancellation policy (hours)"}
    //                                        label={"Cancellation policy (hours)"}
    //                                    />
    //                                </div>
    //                                <div className="flex flex-col gap-2">
    //                                    <FormikInput
    //                                        formikProps={formikProps}
    //                                        name={"name"}
    //                                        placeholder={""}
    //                                        label={"Maximum bookings/day"}
    //                                    />
    //                                </div>
    //                                <div className="mt-5">
    //                                    {/*<Button*/}
    //                                    {/*    className="mt-5"*/}
    //                                    {/*    onClick={(e) => {*/}
    //                                    {/*        e.preventDefault();*/}
    //                                    {/*        formikProps.handleSubmit();*/}
    //                                    {/*    }}*/}
    //                                    {/*    disabled={formikProps.isSubmitting}*/}
    //                                    {/*    type="button"*/}
    //                                    {/*>*/}
    //                                    {/*    {"  Save Changes  "}*/}
    //                                    {/*</Button>*/}
    //                                </div>
    //                            </div>
    //                        </div>
    //                    </div>
    //                </CardContent>
    //            </Card>
    //        )}
    //    </Formik>
    //);

    return (
        <div className="w-full">
            <Tabs defaultValue="basic" className="w-full inline-block">
                <TabsList className=" border bg-background  ">
                    <TabsTrigger
                        className="data-[state=active]:bg-primary data-[state=active]:text-primary-foreground"
                        value="basic"
                        onClick={() => handleTabChange("basic")}
                    >
                        <Icon icon="lucide:settings-2" className="h-5 w-5  mr-2 " />
                        Basic
                    </TabsTrigger>
                    <TabsTrigger
                        value="timings"
                        className="data-[state=active]:bg-primary data-[state=active]:text-primary-foreground"
                    >
                        <Icon icon="lucide:calendar-clock" className="h-5 w-5  mr-2 " />
                        Timings
                    </TabsTrigger>
                </TabsList>
                <TabsContent value="basic" >{_renderBasic()}</TabsContent>
                <TabsContent value="timings">{_renderTimings()}</TabsContent>
            </Tabs>
        </div>
    );
}

