import { useEffect, useState } from "react";
import { Button } from "../../../components/ui/button";
import Common from "../../../helper/common";
import {
  respEnum,
  TournamentTypeEnum,
  TournamentMatchResultEnum,
} from "../../../constants/appConstant";
import { AppSelect } from "../../../components/appComponents";
import Card from "../../../components/ui/card-snippet";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import { FiTrash, FiEdit2 } from "react-icons/fi";

export const columns = [
  { key: "typetournament", label: "Tournamanet" },
  { key: "team1", label: "Team 1" },
  { key: "vs", label: "" },
  { key: "team2", label: "Team 2" },
  { key: "isPlayed", label: "Played" },
  { key: "isResultUploaded", label: "Result Uploaded" },
  { key: "matchResult", label: "Match Result" },
  { key: "createdOn", label: "Schedule On" },
  { key: "action", label: "Action" },
];

export default function Scheduling() {
  const [tournaments, setTournaments] = useState([]);
  const [tournamentId, setTournamentId] = useState();
  const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
  const [tournament, setTournament] = useState({});
  const [scheduledMatches, setScheduledMatches] = useState([]);
  const [selectedTeam1, setSelectedTeam1] = useState();
  const [selectedTeam2, setSelectedTeam2] = useState();
  const [isDropdownEnabled, setIsDropdownEnabled] = useState(false);
  const [editId, setEditId] = useState(null);
  const [selectedTournamentType, setSelectedTournamentType] = useState();
  const [id, setId] = useState();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!isDropdownEnabled) {
      setSelectedTeam1();
      setSelectedTeam2();
      setSelectedTournamentType();
    }
  }, [isDropdownEnabled]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const fetchedId = params.get("id");

    if (fetchedId) {
      setId(fetchedId);
      getUnScheduleTournaments(fetchedId);
      GetTournamentDetails(fetchedId);
      setIsDropdownEnabled(tournamentId !== null);
    } else {
      getUnScheduleTournaments();
    }
  }, []);

  useEffect(() => {
    let t = tournaments.find((t) => t.id == id) || null;
    setTournament(t);
  }, [tournaments]);

  const GetTournamentDetails = async (id) => {
    let response = await Common.ApiService.getInstance().request(
      `GetTournamentDetails?id=${id}`
    );

    let d = response?.data?.[0];
    setTournamentId(d.id);

    const mapMatches = d.matches.map((match) => ({
      ...match,
      typetournament: match.stageType,
      team1: match.teamAName,
      team2: match.teamBName,
    }));

    setScheduledMatches(mapMatches);
  };

  const getUnScheduleTournaments = async (id) => {
    const response = await Common.ApiService.getInstance().request(
      `GetUnScheduleTournaments${id ? "?Schedule=true" : ""}`
      );
      if (response?.data) {
          let d = response?.data;
          let _data = d.map((tournament) => ({
              ...tournament,
              teams: tournament.teams.map((team) => ({
                  ...team,
              })),
          }));
          setTournaments(_data);
      }
  };

  const changeTournament = (val) => {
    setTournamentId(val);
    setIsDropdownEnabled(tournamentId !== null);
    let t = tournaments.find((t) => t.id === val) || null;
    setTournament(t);
    setScheduledMatches([]);
    setSelectedTeam1(undefined);
    setSelectedTeam2(undefined);
    setSelectedTournamentType(null);
    setEditId(null);
  };

  const handleAddTeam = () => {
    if (!selectedTournamentType) {
      Common.showToast("Select Tournament type");
      return;
    }

    if (!selectedTeam1 || !selectedTeam2) {
      Common.showToast("Please select both teams for a match");
      return;
    }
    if (selectedTeam1.id === selectedTeam2.id) {
      Common.showToast("The same team cannot play against itself");
      return;
    }

    const matchExists = scheduledMatches.some(
      (match) =>
        match.id !== editId &&
        ((match.team1 === selectedTeam1.teamName &&
          match.team2 === selectedTeam2.teamName &&
          match.typetournament === selectedTournamentType) ||
          (match.team1 === selectedTeam2.teamName &&
            match.team2 === selectedTeam1.teamName &&
            match.typetournament === selectedTournamentType))
    );

    if (matchExists) {
      Common.showToast(
        "Match is already scheduled for these teams in the tournament type"
      );
      return;
    }

    if (editId) {
      setScheduledMatches((prevMatches) => {
        return prevMatches.map((match) =>
          match.id === editId
            ? {
                ...match,
                team1: selectedTeam1.teamName,
                team2: selectedTeam2.teamName,
                typetournament: selectedTournamentType,
              }
            : match
        );
      });
      setEditId(null);
    } else {
      let newId;
      do {
        newId = Common.Utility.generateShortUniqueId();
      } while (scheduledMatches.some((match) => match.id === newId));

      setScheduledMatches((prevMatches) => [
        ...prevMatches,
        {
          id: newId,
          team1: selectedTeam1.teamName,
          team2: selectedTeam2.teamName,
          typetournament: selectedTournamentType,
        },
      ]);
    }

    setSelectedTeam1();
    setSelectedTeam2();
    setSelectedTournamentType(null);
  };

  const handleEdit = (id) => {
    
    const match = scheduledMatches.find((match) => match.id === id);
    const team1 = tournament.teams.find(
      (team) => team.teamName === match.team1
    );
    const team2 = tournament.teams.find(
      (team) => team.teamName === match.team2
    );
    setSelectedTeam1(team1);
    setSelectedTeam2(team2);
    setSelectedTournamentType(match.typetournament);
    setEditId(id);
  };

  const handleDelete = (id) => {
    setScheduledMatches((prevMatches) =>
      prevMatches.filter((match) => match.id !== id)
    );
    if (id === editId) {
      setEditId(null);
    }
  };

  const Submit = async () => {
    setSaveBtnDisabled(true);

    let groupCounter = 0;
    let data = {
      tournamentId: tournament.id,
      matches: scheduledMatches.map((match) => {
        const teamAId = tournament.teams.find(
          (team) => team.teamName === match.team1
        )?.id;
        const teamBId = tournament.teams.find(
          (team) => team.teamName === match.team2
        )?.id;
        const matchId = typeof match.id === "string" ? 0 : match.id;

        return {
          id: matchId,
          teamAId: teamAId,
          teamBId: teamBId,
          stageType: match.typetournament,
        };
      }),
    };

    if (!data.tournamentId) {
      Common.showToast("Select Tournament", "Error");
      setSaveBtnDisabled(false);
      return;
    }

    const teamIdsWithMatches = new Set(
      data.matches.flatMap((match) => [match.teamAId, match.teamBId])
    );
    const allTeamsHaveMatches = tournament.teams.every((team) =>
      teamIdsWithMatches.has(team.id)
    );

    if (!allTeamsHaveMatches) {
      Common.showToast(
        "Each team must have at least one scheduled match",
        "Error"
      );
      setSaveBtnDisabled(false);
      return;
    }
    let endpoint = id ? "Rescheduling" : "TournamentScheduling";
    let resp = await Common.ApiService.getInstance().request(
      endpoint,
      data,
      "POST"
    );

    if (resp.status === respEnum.Success) {
      Common.SweetAlert.alert(resp.message);
      navigate("/app/tournaments");
    } else {
      setSaveBtnDisabled(false);
    }
  };

  return (
    <>
      <div className="space-y-6 mb-5">
        <Card title="Schedule Tournament">
          <div className="md:grid md:grid-cols-3 gap-6">
            <div className="flex flex-col col-span-3">
              <div className="md:grid md:grid-cols-3 gap-6 mt-6">
                <div className="flex flex-col col-span-3">
                  <div className="md:grid md:grid-cols-2 gap-6 space-y-6 md:space-y-0">
                    <div className="flex flex-col col-span-5 gap-2">
                      <div className="flex gap-4 w-full">
                        <div
                          className="flex-1"
                          style={{
                            flexBasis: "33.33%",
                            maxWidth: "316.64px",
                            paddingLeft: "40px",
                          }}
                        >
                          <AppSelect
                            data={tournaments?.map((item) => ({
                              id: item.id,
                              name: item.name,
                            }))}
                            placeholder="Select Tournament"
                            value={tournamentId}
                            label="Select Tournament"
                            onChange={(val) => changeTournament(val)}
                            disabled={id ? true : false}
                          />
                        </div>

                        {tournament &&
                          tournament.teams &&
                          tournament.teams.length > 0 && (
                            <div>
                              <h3
                                style={{
                                  marginBottom: "8px",
                                  fontWeight: "bold",
                                  textAlign: "center",
                                  fontSize: "large",
                                }}
                              >
                                Teams
                              </h3>{" "}
                              {/* Add heading */}
                              <div
                                className="flex-2"
                                style={{
                                  width: "545px",
                                  display: "flex", // Flex display
                                  flexWrap: "wrap", // Allow wrapping to the next line
                                  gap: "4px", // Space between buttons
                                  border: "1px solid #ccc",
                                  padding: "8px",
                                  borderRadius: "8px",
                                  marginLeft: "56px",
                                }}
                              >
                                {tournament.teams.map((item) => (
                                  <Button
                                    key={item.id}
                                    style={{
                                      minWidth: "auto",
                                      cursor: "default",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {item.teamName}
                                  </Button>
                                ))}
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Match Scheduling Section */}
              <div className="md:grid md:grid-cols-1 gap-6 mt-6 mb-4">
                <div className="flex flex-col col-span-2">
                  <div
                    className="md:grid md:grid-cols-4 gap-2"
                    style={{ paddingLeft: "40px" }}
                  >
                    <div className="flex flex-col col-span-1 gap-2">
                      <AppSelect
                        value={selectedTournamentType}
                        onChange={setSelectedTournamentType}
                        placeholder="Select Type"
                        label="Tournament Type"
                        data={[
                          { id: null, name: "Select" },
                          ...Common.Utility.enumToArray(TournamentTypeEnum),
                        ]}
                        disabled={!isDropdownEnabled}
                      />
                    </div>

                    {/* Team Selection and VS Section */}
                    <div className="flex items-center col-span-2 justify-center gap-4">
                      <AppSelect
                        data={tournament?.teams?.map((item) => ({
                          id: item.id,
                          name: item.teamName,
                        }))}
                        placeholder="Select Team 1"
                        value={selectedTeam1 ? selectedTeam1.id : ""}
                        label="Team 1"
                        onChange={(val) => {
                          const team1 = tournament.teams.find(
                            (team) => team.id === val
                          );
                          setSelectedTeam1(team1);
                        }}
                        disabled={!isDropdownEnabled}
                        className="min-w-[200px]"
                      />
                      <span className="text-lg font-semibold mt-4">vs</span>
                      <AppSelect
                        data={tournament?.teams?.map((item) => ({
                          id: item.id,
                          name: item.teamName,
                        }))}
                        placeholder="Select Team 2"
                        value={selectedTeam2 ? selectedTeam2.id : ""}
                        label="Team 2"
                        onChange={(val) => {
                          const team2 = tournament.teams.find(
                            (team) => team.id === val
                          );
                          setSelectedTeam2(team2);
                        }}
                        disabled={!isDropdownEnabled}
                        className="min-w-[200px]"
                      />
                    </div>

                    {/* Update/Add Match Button */}
                    <div className="flex flex-col col-span-1 gap-1 justify-center mt-4">
                      <Button
                        className="max-w-[115px] "
                        onClick={handleAddTeam}
                      >
                        {editId !== null ? "Update Match" : "Add Match"}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>

        <div
          className="grid grid-cols-1 gap-4"
          style={{ maxWidth: "90%", margin: "0 auto" }}
        >
          {Object.keys(TournamentTypeEnum).map((type) => {
            const typeMatches = scheduledMatches.filter(
              (match) => match.typetournament === TournamentTypeEnum[type]
            );
            global.log(typeMatches);
            if (typeMatches.length === 0) return null;
            return (
              <Card
                title={
                  <div className="text-center">{type.replace(/_/g, " ")}</div>
                }
                key={type}
                className="max-w-full"
              >
                <div className="flex flex-col">
                  <div
                    className="col-span-4"
                    style={{ maxHeight: "189px", overflowY: "auto" }}
                  >
                    <Table>
                      <TableHeader>
                        <TableRow>
                          {columns.map((column) => (
                            <TableHead
                              key={column.key}
                              style={{
                                display:
                                  column.key === "typetournament"
                                    ? "none"
                                    : "table-cell",
                              }}
                            >
                              {column.label}
                            </TableHead>
                          ))}
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        {typeMatches.map((match, index) => (
                          <TableRow key={index}>
                            <TableCell style={{ display: "none" }}>
                              {type.replace(/_/g, " ")}
                            </TableCell>
                            <TableCell>{match.team1}</TableCell>
                            <TableCell>vs</TableCell>
                            <TableCell>{match.team2}</TableCell>
                            <TableCell>
                              {match.isPlayed ? "Yes" : "No"}
                            </TableCell>
                            <TableCell>
                              {match.isResultUploaded ? "Yes" : "No"}
                            </TableCell>
                            <TableCell>
                              {" "}
                              {Common.Utility.getKeyByValue(
                                TournamentMatchResultEnum,
                                match.matchResult
                              ) || "No Result"}
                            </TableCell>
                            <TableCell>
                              {match.createdOn ? match.createdOn : "-"}
                            </TableCell>

                            <TableCell>
                              <div className="flex items-center">
                                {!match.isPlayed && (
                                  <FiEdit2
                                    className="text-blue-500 cursor-pointer mr-2"
                                    onClick={() => handleEdit(match.id)}
                                  />
                                )}
                                {!match.isPlayed && (
                                  <FiTrash
                                    className="text-red-500 cursor-pointer ml-2"
                                    onClick={() => handleDelete(match.id)}
                                  />
                                )}
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                </div>
              </Card>
            );
          })}
        </div>
      </div>

      <div className="flex flex-col items-end mt-5">
        <Button onClick={Submit} disabled={saveBtnDisabled}>
          {saveBtnDisabled ? "Saving..." : "Save"}
        </Button>
      </div>
    </>
  );
}
