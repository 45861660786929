import React, { useEffect, useState } from "react";
import Card from "../../../components/ui/card-snippet";
import { respEnum } from "../../../constants/appConstant";
import Common from "../../../helper/common";
import { AppInput, AppSearchSectionSelect } from "../../../components/appComponents";
import Utility from "../../../helper/utilityHelper";
import { Button } from "../../../components/ui/button";

export default function Balance() {
    const [accountDetails, setAccountDetails] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [tillDate, setTillDate] = useState(null);
    const [clubAccountId, setClubAccountId] = useState(null);
    const [paymentTypeList, setPaymentTypeList] = useState([]);
    const [paymentGateway, setPaymentGateway] = useState([]);
    const [paymentGatewayId, setPaymentGatewayId] = useState(null);

    useEffect(() => {
        getClubPaymentTypes();
        getPaymentGateway();
        fetchAccountBalance();
    }, []);


    useEffect(() => {
        fetchAccountBalance();
    }, [startDate, tillDate, clubAccountId, paymentGatewayId]);


    const resetFilters = () => {
        setStartDate(null);
        setTillDate(null);
        setClubAccountId(null);
        setPaymentGatewayId(null);
    };

    const getClubPaymentTypes = async () => {
        const response = await Common.ApiService.getInstance().request(
            "GetClubPaymentTypes?isForReport=false"
        );

        if (response?.status == respEnum.Success) {
            setPaymentTypeList(response?.data ?? []);
        }
    };

    const getPaymentGateway = async () => {
        const response = await Common.ApiService.getInstance().request(
            "GetClubPaymentGateways"
        );

        if (response?.status == respEnum.Success) {
            setPaymentGateway(response?.data ?? []);
        }
    };

    const fetchAccountBalance = async () => {
        if (startDate && tillDate && startDate >= tillDate) {
            Common.showToast("End Time must be greater", "Error", "info");
            return;
        }
        if (paymentGatewayId?.value && clubAccountId?.value) {
            Common.showToast("You Can't Select Both Club Accounts and Payment Gateway ", "Error", "info");
            return;
        }
        const params = new URLSearchParams();

        if (startDate) {
            params.append('startDateTime', startDate);
        }

        if (tillDate) {
            params.append('tillDate', tillDate);
        }

        if (clubAccountId?.value) {
            params.append('clubAccountId', clubAccountId.value);
        }

        if (paymentGatewayId?.value) {
            params.append('paymentGatewayId', paymentGatewayId.value);
        }

        const response = await Common.ApiService.getInstance().request(
            `GetPaymentSummary?${params.toString()}`
        );

        if (response?.status === respEnum.Success) {
            setAccountDetails(response.data[0] || []);
        }
    };


    return (
        <div className="space-y-6 mb-6">
            <Card title="Account Balance">
                <div className="grid grid-cols-1 sm:grid-cols-5 gap-4 mt-5">

                    <AppInput
                        type={"date"}
                        value={startDate}
                        onChange={(e) => {
                            const selectedstartDate = e;
                            if (tillDate && selectedstartDate > tillDate) {
                                Common.showToast("Start Time must be lesser than End Time", "Error", "info");
                                setStartDate(null);
                            } else {
                                setStartDate(selectedstartDate);
                            }
                        }}
                        label="From"
                        className="mb-3 col-span-1 pl-6"
                    />

                    <AppInput
                        type={"date"}
                        value={tillDate}
                        onChange={(e) => {
                            const selectedEndDate = e;
                            if (selectedEndDate < startDate) {
                                Common.showToast("End Time must be greater", "Error", "info");
                                setTillDate("");
                            } else {
                                setTillDate(selectedEndDate);
                            }
                        }}
                        label="Till"
                        className="mb-3 col-span-1"
                    />

                    <AppSearchSectionSelect
                        value={clubAccountId}
                        onChange={setClubAccountId}
                        label="Account"
                        data={paymentTypeList}
                        className="mb-5 col-span-1"
                    />

                    <AppSearchSectionSelect
                        value={paymentGatewayId}
                        onChange={setPaymentGatewayId}
                        label={"Payment Gateway"}
                        data={paymentGateway}
                        className="mb-5 col-span-1"
                    />

                    <Button className="lg:mt-6 h-8 mb-6 w-[120px] px-2 col-span-1" onClick={resetFilters}>
                        Reset Filter
                    </Button>




                </div>
            </Card>

            {/* Results Table */}
            {(!accountDetails || Object.keys(accountDetails).length > 0) &&
                (
                    <Card title="">

                        <table className="min-w-full divide-y divide-gray-200 border">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th
                                        scope="col"
                                        colSpan={2}
                                        className="px-6 py-3 text-center text-sm font-bold text-gray-700 uppercase tracking-wider"
                                    >
                                        Payments
                                    </th>
                                </tr>
                            </thead>


                            <tbody className="bg-white divide-y divide-gray-200 font-bold">
                                {Object.entries(accountDetails).map(([key, value]) => {
                                    const keyMapping = {
                                        paymentReceived: "Received",
                                        paymentRefundable: "Refundable",
                                        paymentRefunded: "Refunded",
                                    };

                                    const displayKey = keyMapping[key] || key.replace(/_/g, " ");

                                    return (
                                        <tr key={key} className="" >
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700 text-center">
                                                {displayKey}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">
                                                {Utility.formatNumber(value)}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>

                        </table>
                    </Card>
                )}

        </div>
    );
}
