import React, { useState } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "./card";
import { Collapsible, CollapsibleContent } from "./collapsible";
import { Switch } from "./switch";
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";

import { hslToHex } from "../../helper/utilityHelper";
import { themes } from "../../config/thems";
import { useThemeStore } from "../../store";
import { useTheme } from "../../hooks/useTheme";
import { Input } from "./input"; 

const CardSnippet = ({ title, code, addButton, children, searchQuery, handleSearchChange }) => {
    const [show, setShow] = useState(false);
    const toggle = () => {
        setShow((prevShow) => !prevShow);
    };

    const { theme: mode } = useTheme();
    const { theme: config, setTheme: setConfig } = useThemeStore();
    const newTheme = themes.find((theme) => theme.name === config);

  const hslPrimary = `hsla(${
    newTheme?.cssVars[mode === "dark" ? "dark" : "light"][
      "secondary-foreground"
    ]
        })`;
  const hslPrimary2 = `hsla(${
    newTheme?.cssVars[mode === "dark" ? "dark" : "light"].secondary
        })`;

    const hexPrimary = hslToHex(hslPrimary);
    const hexPrimary2 = hslToHex(hslPrimary2);
    return (
        <Card>
            <CardHeader className="flex flex-row items-center justify-between">
             
                {title && (
                    <CardTitle className="flex-1 leading-normal">{title}</CardTitle>
                )}
                {/* Conditionally render the search input */}
                {searchQuery !== null && searchQuery !== undefined && (
                    <div className="mr-3">
                        <Input
                            type="text"
                            placeholder="Search..."
                            value={searchQuery}
                            onChange={handleSearchChange}
                            className="w-64 p-2 border border-gray-300 rounded"
                        />
                    </div>
                )}
                {code && (
                    <div className="flex-none">
                        <Switch id="airplane-mode" onClick={toggle} />
                    </div>
                )}
                {addButton && addButton()}
        {/* {showButton && <Button onClick={onButtonClick}>{buttonTitle}</Button>} */}
            </CardHeader>
            <CardContent>
                {children}
                <Collapsible open={show}>
                    <CollapsibleContent className="CollapsibleContent">
                        <SyntaxHighlighter
                            language="javascript"
              className=" rounded-md  text-sm mt-6 "
                            style={atomOneDark}
                            customStyle={{
                                padding: "24px",
                                backgroundColor: mode !== "dark" ? hexPrimary : hexPrimary2,
                            }}
                        >
                            {code}
                        </SyntaxHighlighter>
                    </CollapsibleContent>
                </Collapsible>
            </CardContent>
        </Card>
    );
};

export default CardSnippet;
