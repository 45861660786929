import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import Common from "../../helper/common";
import { Button } from "../../components/ui/button";
import AdvanceTable from "../../components/advanceTable";

const Clubs = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  useEffect(() => {
    getData(1);
  }, []);

  const getData = async (
    pageNumber,
    searchTerm = "",
    recordsToDisplay = null
  ) => {
    setLoading(true);
    const response = await Common.ApiService.getInstance().request(
      "GetClubs",
      {
        pageNumber: pageNumber,
        q: searchTerm,
        isactive: null,
        countryId: null,
        cityId: null,
        stateId: null,
        isapproved: true,
        isPaid: null,
        recordsToDisplay,
      },
      "Post"
      );
      debugger
    if (response?.data) {
        let obj = {
            ...response.data[0],
            data: response.data[0].data.map((item) => ({
                ...item,
                facilities: item.facilities
                    ? item.facilities.map((facility) => facility.masterFacilityName).join(", ")
                    : "",
            })),
        };

      setData(obj);
      setLoading(false);
    }
  };

  const actions = [
    // {
    //   type: "edit",
    // },
    // {
    //   type: "delete",
    //   // onClick: _onDelete,
    // },
    // {
    //   type: "view",
    //   onClick: _onView,
    // },
  ];

  const _addUpdateTrigger = () => (
    <Button size="icon" variant="outline" color="secondary" className="h-7 w-7">
      <Icon icon="heroicons:pencil" className="h-4 w-4" />
    </Button>
  );

  let columns = [
    { title: "Club Name", type: "text", key: "name" },
    { title: "City", type: "date", key: "cityName" },
    { title: "Contact #", type: "text", key: "phone" },
    { title: "Postal Code", type: "text", key: "postalCode" },
    { title: "Starting From", type: "text", key: "startFrom" },
   /* { title: "Facilities", type: "text", key: "facilities" },*/
    {
      title: "Is Active",
      type: "toggle",
      isVisible: true,
      key: "isactive",
      // onChanged: _onIsOnMobileChange,
    },
  ];

  return (
    <>
      {data?.data && (
        <AdvanceTable
          title={"Clubs"}
          columns={columns}
          data={data}
          getData={getData}
          showPagination
          showAddButton
          updateTrigger={_addUpdateTrigger}
          actionButtons={actions}

          // updateTrigger={(id) => global.log("Update triggered for id:", id)} // Example function
        />
      )}
    </>
  );
};

export default Clubs;
