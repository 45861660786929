import React, { useEffect, useState } from "react";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import format from "date-fns/format";
import { Formik } from "formik";
import * as yup from "yup";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import parse from "date-fns/parse";
import { Button } from "../../components/ui/button";
import DialogModal from "../../components/partials/modal/dialogModal";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { ChevronDown, Edit, Trash2, View, Clock } from "lucide-react";
// import "../../assets/nascent.css";
import { useNavigate } from "react-router-dom";
import common from "../../helper/common";
import {
  BookingStatusEnum,
  DateminFormat,
  PaymentStatusEnum,
  respEnum,
  ToCSharpFormat,
} from "../../constants/appConstant";
import AddBooking from "../clubbookings/components/addBooking";
import moment from "moment";
import { AppInput, AppSelect } from "../../components/appComponents";
import AddUpdateModal from "../../components/formComponents/addUpdateModal";
import { useQueryParams } from "../../contexts/queryParamsContext";
import Common from "../../helper/common";
import {
  FormikInput,
  FormikSearchSectionSelect,
  FormikDate,
} from "../../components/formik-components";
import { useDispatch, useSelector } from "react-redux";
import { setCalendarDate, setSportId } from "../../store/bookingSlice";

const locales = {
  "en-US": require("date-fns/locale/en-US"),
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const BookingCalendar2 = () => {
  const [events, setEvents] = useState([]);
  const [booking, setBooking] = useState([]);
  const [resources, setResources] = useState([]);
  const [courtList, setCourtList] = useState([]);
  // const [selectedSport, setSelectedSport] = useState(null);
  // const [selectedDate, setSelectedDate] = useState(new Date());
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const [sports, setSports] = useState([]);
  const [showAdd, setShowAdd] = useState(false);
  const [showReschedule, setShowReschedule] = useState(false);
  const [bookingValues, setBookingValues] = useState({});
  const [selectedBooking, setSelectedBooking] = useState([]);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { calendarDate: selectedDate, sportId: selectedSport } = useSelector(
    (state) => state.booking
  );

  const handleDateChange = (date) => {
    dispatch(setCalendarDate(date));
  };

  const handleSportChange = (sporIdt) => {
    dispatch(setSportId(sporIdt));
  };

  // const [params, setParams] = useState({});
  const { params, setParams } = useQueryParams();

  useEffect(() => {
    const style = document.createElement("style");
    style.textContent = `
      @media (min-width: 768px) {
        .resource-fixed {
          position: fixed;
          top: 60px;
          z-index: 999;
          background: white;
        }
      }
    `;
    document.head.appendChild(style);

    const handleScroll = () => {
      if (window.innerWidth < 768) return;

      const resourceRows = document.querySelectorAll(
        ".rbc-row.rbc-row-resource"
      );
      if (!resourceRows.length) return;

      if (window.scrollY >= 150) {
        resourceRows.forEach((row) => row.classList.add("resource-fixed"));
      } else {
        resourceRows.forEach((row) => row.classList.remove("resource-fixed"));
      }
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleScroll);
      document.head.removeChild(style);
    };
  }, []);

  useEffect(() => {
    GetSports();
    getCourts();
  }, []);

  useEffect(() => {
    if (selectedSport !== undefined && selectedSport !== null) {
      fetchCourtWiseBookings();
    }
  }, [selectedSport, selectedDate]);

  const formRescheduleValidations = yup.object({
    courtId: yup.object().required("Court is Requied"),
    bookingDate: yup
      .date()
      .required("Booking Date is Requied")
      .min(Common.Utility.today, "Date should be greater than previous date "),
    startDateTime: yup
      .string()
      .required("Start Time is required")
      .test(
        "is-valid-time",
        "Start Time format should be in 00, 30 or 23:59 format",
        (value) => Common.Utility.CheckTime(value)
      )
      .matches(
        /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/,
        "Invalid time format (HH:mm)"
      ),

    endDateTime: yup
      .string()
      .required("End time is required")
      .test(
        "is-valid-time",
        "End Time format should be in 00, 30 23:59 format",
        (value) => Common.Utility.CheckTime(value)
      )
      .matches(
        /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/,
        "Invalid time format (HH:mm)"
      ),
  });

  const GetSports = async () => {
    try {
      const response = await common.ApiService.getInstance().request(
        "GetClubSports"
      );
      const sportsData = [
        { id: null, name: "Select Sport" },
        ...(response?.data ?? []),
      ];

      setSports(sportsData);

      if (sportsData.length > 1) {
        const firstSportId = sportsData[1].id;
        handleSportChange(firstSportId);
      }
    } catch (error) {
      console.error("Error fetching sports:", error);
    }
  };

  const getCourts = async () => {
    const response = await Common.ApiService.getInstance().request(
      "GetSportWiseCourtddl"
    );
    if (response?.status == respEnum.Success) {
      setCourtList(response?.data ?? []);
    }
  };

  const fetchCourtWiseBookings = async () => {
    try {
      const formattedDate = moment(selectedDate).format("yyyy-MM-DD");
      const response = await common.ApiService.getInstance().request(
        `GetCourtWiseBookingsByDate?sportId=${selectedSport}&date=${formattedDate}`
      );

      if (response.status == respEnum.Success) {
        const calendarEvents = response.data.flatMap((court) =>
          court.bookings.map((booking) => ({
            id: booking.id,
            title: booking.playerName, //booking.bookingDetail || 'Booked',
            start: new Date(booking.startDateTime),
            end: new Date(booking.endDateTime),
            resourceId: court.courtId,
            paymentStatus: booking.paymentStatus,
            startDateTime: booking.startDateTime,
            // color: "green"
          }))
        );

        const bookingEvent = response.data.flatMap((court) =>
          court.bookings.map((booking) => ({
            id: booking.id,
            title: booking.playerName,
            bookingDate: booking.startDateTime.split("T")[0],
            startDateTime: booking.startDateTime,
            endDateTime: booking.endDateTime,
            courtId: court.courtId,
          }))
        );

        const calendarResources = response.data.map((court) => ({
          id: court.courtId,
          title: court.courtName,
        }));

        setEvents(calendarEvents);
        setBooking(bookingEvent);
        setResources(calendarResources);
      }
    } catch (error) {
      console.error("Error fetching court bookings:", error);
    }
  };

  const handleNavigate = (newDate, view, action) => {
    handleDateChange(newDate);
  };

  // Add new event
  const handleSelect = ({ start, end, resourceId }) => {
    setShowAdd(true);

    setBookingValues({
      courtId: resourceId,
      startDateTime: moment(new Date(start)).format("HH:mm"),
      endDateTime: moment(new Date(end)).format("HH:mm"),
    });
  };

  // Edit event
  // const handleEditEvent = (event) => {
  //   const newTitle = window.prompt("Edit Event name", event.title);
  //   if (newTitle !== null) {
  //     setEvents(
  //       events.map((evt) =>
  //         evt.id === event.id ? { ...evt, title: newTitle } : evt
  //       )
  //     );
  //     setOpenDropdownId(null);
  //   }
  // };

  // Cancel/Delete event
  // const handleCancelEvent = (event) => {
  //   const confirmCancel = window.confirm(
  //     "Are you sure you want to cancel this event?"
  //   );
  //   if (confirmCancel) {
  //     setEvents(events.filter((evt) => evt.id !== event.id));
  //     setOpenDropdownId(null);
  //   }
  // };

  const cancelledBooking = async (event) => {
    let data = {
      bookingId: +event?.id,
      status: +BookingStatusEnum.Cancelled_By_Club,
    };

    let resp = await common.ApiService.getInstance().request(
      "UpdateBookingStatus",
      data,
      "Patch"
    );
    if (resp.status == respEnum.Success) {
      common.SweetAlert.alert("Booking Cancelled Successfully", "success");
      setEvents(events.filter((evt) => evt.id !== event.id));
      setOpenDropdownId(null);
    }
  };

  const _handleOnClose = (shouldRefresh) => {
    setShowAdd(false);
    if (shouldRefresh) {
      fetchCourtWiseBookings();
    }
  };

  const Reschedule = async (d) => {
    debugger;
    let data = {
      ...d,
      bookingId: selectedBooking?.id,
      courtId: d.courtId.value,
      newStartDateTime: Common.moment(
        `${d.bookingDate} ${d.startDateTime}`
      ).format(ToCSharpFormat),
      newEndDateTime: Common.moment(`${d.bookingDate} ${d.endDateTime}`).format(
        ToCSharpFormat
      ),
    };

    if (data.newStartDateTime >= data.newEndDateTime) {
      toast.error(`End time must be greater`);
      return;
    }

    const now = dayjs();
    if (
      dayjs(data.newStartDateTime).isBefore(now) ||
      dayjs(data.newEndDateTime).isBefore(now)
    ) {
      toast.error(`Start Time and End Time must not be in the past`);
      return;
    }

    if (data.courtId === selectedBooking.courtId) {
      if (
        data.newStartDateTime === selectedBooking.startDateTime ||
        data.newEndDateTime === selectedBooking.endDateTime
      ) {
        toast.error(`Start and End datetime must be different`);
        return;
      }
    }

    const originalDuration = dayjs(selectedBooking?.endDateTime).diff(
      dayjs(selectedBooking?.startDateTime),
      "minute"
    );
    const newDuration = dayjs(data.newEndDateTime).diff(
      dayjs(data.newStartDateTime),
      "minute"
    );

    if (
      newDuration != originalDuration - 1 &&
      newDuration != originalDuration
    ) {
      toast.error(`The duration must be (${originalDuration} minutes)`);

      return;
    }

    setShowReschedule(false);
    let resp = await Common.ApiService.getInstance().request(
      "BookingRescheduling",
      data,
      "POST"
    );
    if (resp.status == respEnum.Success) {
      Common.SweetAlert.alert(resp.message, "success");
      setTimeout(() => {
        fetchCourtWiseBookings();
      }, 500);
    }
  };

  const _renderReschedule = () => (
    <div>
      <Formik
        onSubmit={Reschedule}
        validationSchema={formRescheduleValidations}
        enableReinitialize
        initialValues={{
          title: selectedBooking?.title,
          bookingDate: selectedBooking?.startDateTime
            ? Common.moment(selectedBooking.startDateTime).format(DateminFormat)
            : Common.moment().format(DateminFormat),
          courtId: selectedBooking?.courtId
            ? courtList
                .flatMap((category) => category.options)
                .find((court) => court.value === selectedBooking.courtId) ||
              null
            : null,

          startDateTime: selectedBooking?.startDateTime
            ? Common.moment(selectedBooking.startDateTime).format("HH:mm")
            : "",
          endDateTime: selectedBooking?.endDateTime
            ? Common.moment(selectedBooking.endDateTime).format("HH:mm")
            : "",
        }}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {(formikProps) => (
          <>
            <div className="mb-3">
              <FormikInput
                formikProps={formikProps}
                name={"title"}
                label={"Player Name"}
                disabled={true}
                //    className="w-full"
              />
            </div>

            <div className="mb-3">
              <FormikSearchSectionSelect
                formikProps={formikProps}
                name={"courtId"}
                label={"Court"}
                data={courtList}
              />
            </div>

            <div>
              <FormikDate
                formikProps={formikProps}
                name={"bookingDate"}
                type={"date"}
                label={"Booking Date"}
              />
            </div>

            <div className="md:grid md:grid-cols-2 gap-6 items-center mt-4">
              <div className="flex flex-col">
                <FormikInput
                  formikProps={formikProps}
                  name={"startDateTime"}
                  placeholder={"Start Time"}
                  label={"Start Time"}
                  type={"time"}
                  className="w-full"
                />
              </div>

              <div className="flex flex-col">
                <FormikInput
                  formikProps={formikProps}
                  name={"endDateTime"}
                  placeholder={"End Time"}
                  label={"End Time"}
                  type={"time"}
                  className="w-full"
                />
              </div>
            </div>

            <div style={{ marginTop: "20px", textAlign: "right" }}>
              <Button
                color="success"
                onClick={(e) => {
                  e.preventDefault();
                  formikProps.handleSubmit();
                }}
                className="shadow-md mr-3"
              >
                Reschedule
              </Button>
            </div>
          </>
        )}
      </Formik>
    </div>
  );

  const handleReschedule = (bookingId) => {
    const filteredBooking = booking.find((booking) => booking.id === bookingId);
    if (filteredBooking) setSelectedBooking(filteredBooking);
    setShowReschedule(true);
  };

  // Custom Event Component with Dropdown
  const CustomEvent = ({ event }) => {
    const isDropdownOpen = openDropdownId === event.id;

    return (
      <div className="relative ">
        <div className="flex items-center justify-between">
          <span className="truncate">{event.title}</span>
          <button
            onClick={(e) => {
              e.stopPropagation();
              setOpenDropdownId(isDropdownOpen ? null : event.id);
            }}
            className="ml-2 text-gray-500 hover:text-gray-700 action-btn-dd "
          >
            <ChevronDown size={16} />
          </button>
        </div>

        {isDropdownOpen && (
          <div className="absolute z-10 right-0 mt-1 w-36 bg-white border rounded shadow-lg">
            <button
              onClick={(e) => {
                e.stopPropagation();
                setParams({
                  ...params,
                  bookingId: event.id,
                });
                navigate(`/app/bookingDetails`);
              }}
              className="flex items-center w-full px-3 py-2 text-primary "
            >
              <View size={14} className="mr-2" /> View Details
            </button>
            {moment(event?.startDateTime).isSameOrAfter(
              Common.Utility.GetCurrentDateTime()
            ) && (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleReschedule(event.id);
                }}
                className="flex items-center w-full px-3 py-2 text-blue-600 hover:bg-gray-100"
              >
                <Clock size={14} className="mr-2" /> Reschedule
              </button>
            )}
            <button
              onClick={async (e) => {
                e.stopPropagation();

                const isConfirmed = await common.SweetAlert.confirmation(
                  "Are you sure you want to cancel this booking? This can't be reversed!",
                  "Confirm Cancel"
                );

                if (isConfirmed) {
                  cancelledBooking(event);
                }
              }}
              className="flex items-center w-full px-3 py-2 text-red-600 hover:bg-gray-100"
            >
              <Trash2 size={14} className="mr-2" /> Cancel Booking
            </button>
          </div>
        )}

        {showReschedule && (
          <DialogModal
            title={"Reschedule"}
            open={showReschedule}
            setOpen={setShowReschedule}
            Child={_renderReschedule}
            onClose={() => setShowReschedule(false)}
          />
        )}
      </div>
    );
  };

  const isSlotOverlapping = (slotInfo, events) => {
    return events.some(
      (event) =>
        // Check if the resource matches
        event.resourceId === slotInfo.resourceId &&
        // Check time overlap
        slotInfo.start < event.end &&
        slotInfo.end > event.start
    );
  };

  const getEventClassName = (event) => {
    let className = "payment-icon";

    if (event.paymentStatus === PaymentStatusEnum.Paid) {
      className += " pay-paid";
    } else if (event.paymentStatus === PaymentStatusEnum.Partial_Paid) {
      className += " pay-partial";
    } else if (event.paymentStatus === PaymentStatusEnum.Un_Paid) {
      className += " pay-unpaid";
    }

    return className;
  };

  return (
    <div>
      <div className="min-h-screen flex flex-col items-centerx">
        <div className="md:grid md:grid-cols-4 grid grid-cols-2 gap-3 mb-5">
          <div className="flex flex-col gap-3">
            <AppSelect
              name={"sportId"}
              data={sports}
              value={selectedSport}
              placeholder={"Select Sport"}
              label={"Sport"}
              onChange={handleSportChange}
            />
          </div>

          <div className="flex flex-col gap-3 ">
            <AppInput
              value={moment(selectedDate).format(DateminFormat)}
              type={"date"}
              label={"Date"}
              onChange={handleDateChange}
            />
          </div>
        </div>
        <div className="bg-white shadow-md py-3 rounded-lg w-full">
          <div className="h-100 p-0">
            <Calendar
              localizer={localizer}
              events={events}
              resources={resources}
              resourceIdAccessor="id"
              resourceTitleAccessor="title"
              views={["day"]}
              defaultView="day"
              selectable
              date={selectedDate}
              onNavigate={handleNavigate}
              onSelectSlot={(slotInfo) => {
                if (!isSlotOverlapping(slotInfo, events)) {
                  handleSelect(slotInfo);
                }
              }}
              components={{
                event: CustomEvent,
              }}
              style={{ height: "100%" }}
              eventPropGetter={(event) => ({
                className: getEventClassName(event),
              })}
            />
          </div>
        </div>
      </div>
      <AddUpdateModal
        open={showAdd}
        setOpen={setShowAdd}
        Component={(props) => (
          <AddBooking
            {...props}
            onClose={_handleOnClose}
            bookingValues={{
              ...bookingValues,
              paymentStatus: PaymentStatusEnum.Un_Paid,
              bookingDate: moment(selectedDate).format(DateminFormat),
            }}
          />
        )}
        title={"Create Booking"}
      />
    </div>
  );
};

export default BookingCalendar2;
