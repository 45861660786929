import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Icon } from "@iconify/react";
import * as yup from "yup";
import { Button } from "../../../components/ui/button";
import Card from "../../../components/ui/card-snippet";
import { useQueryParams } from "../../../contexts/queryParamsContext";
import { useLocation, useNavigate } from "react-router-dom";
import {
    ClubPaymentLogTypeEnum,
    respEnum,
    IntMinLevel,
    IntMax,
    PaymentStatusEnum,
} from "../../../constants/appConstant";
import {
    FormikInput,
    FormikSearchSelect,
    FormikSearchSectionSelect,
} from "../../../components/formik-components";
import Common from "../../../helper/common";
import {
    BreadcrumbItem,
    Breadcrumbs,
} from "../../../components/ui/breadcrumbs";

const formValidations = yup.object({
    playerId: yup.number().required("Customer is Required"),
    adjustAmount: yup
        .number()
        .required("Adjust Amount is required")
        .min(IntMinLevel),
});

export default function AddAdjustment() {
    const navigate = useNavigate();
    const location = useLocation();
    const formikRef = useRef();
    const [customerList, setCustomerList] = useState([]);
    const [bookingList, setBookingList] = useState([]);
    const [id, setId] = useState(null);
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const { params, setParams } = useQueryParams();
    var remainingAdjustAmounts = 0;

    const formInitialValue = {
        bookings: bookingList.map((booking) => ({
            id: booking.id,
            isSelected: false,
            amount: null,
        })),
    };

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const fetchedId = params.get("id");
        if (fetchedId) {
            setId(fetchedId);
            GetDetails(fetchedId);
        }
    }, []);

    useEffect(() => {
        getCustomers();
        Common.delay();
    }, []);

    const getCustomers = async () => {
        return new Promise(async (res) => {
            const response = await Common.ApiService.getInstance().request(
                "ClubPlayersddl",
                {},
                "Get",
                false
            );
            const customerData = response?.data ?? [];

            const updatedCustomerList = [
                { id: null, name: "Select Customer", phone: "" },
                ...customerData.map((customer) => ({
                    id: customer.id,
                    name: customer.name,
                    phone: customer.phone,
                })),
            ];
            setCustomerList(updatedCustomerList);

            setTimeout(res, 1000);
        });
    };

    const getCustomerBooking = async (playerId, fromDate, tillDate) => {
        if (!playerId) {
            setBookingList([]);
            formikRef.current.setFieldValue("adjustAmount", "");
            formikRef.current.setFieldValue("balance", "");
            formikRef.current.setFieldValue("pendingAmount", "");
            formikRef.current.setFieldValue("playerBalAfterAdjustment", "");
            formikRef.current.values.bookings.forEach((_, index) => {
                formikRef.current.setFieldValue(`bookings[${index}].amount`, "");
                formikRef.current.setFieldValue(`bookings[${index}].isSelected`, false);
            });
            return;
        }
        debugger

        if (!fromDate && !tillDate) {
            fromDate = null;
            tillDate = null;
        }

        else if (fromDate && tillDate) {
            if (fromDate > tillDate) {
                Common.showToast("From Date cannot be greater than Till Date", "error");
                return;
            }
            else {
                formikRef.current.values.bookings.forEach((_, index) => {
                    formikRef.current.setFieldValue(`bookings[${index}].amount`, "");
                    formikRef.current.setFieldValue(`bookings[${index}].isSelected`, false);
                });
            }
        }

        let url = `GetCustomerUnpaidBooking?playerId=${playerId}&fromDate=${fromDate ?? ''}&tillDate=${tillDate ?? ''}`;
        let response = await Common.ApiService.getInstance().request(url);

        const data = response?.data ?? {};
        const balance = data?.balance ?? 0;
        const pendingAmount = data?.pendingAmount ?? 0;
        let bookings = Array.isArray(data?.bookings) ? data.bookings : [];

        if (formikRef.current) {
            formikRef.current.setFieldValue("balance", balance);
            formikRef.current.setFieldValue("adjustAmount", "");
            formikRef.current.setFieldValue("pendingAmount", pendingAmount);
            remainingAdjustAmounts = 0;

            bookings.forEach((booking, index) => {
                formikRef.current.setFieldValue(`bookings[${index}].isSelected`, true);
            });
        }

        setBookingList(bookings);
        setSelectAllChecked(true);
    };


    const handleAdjust = async (formikProps) => {
        const { values } = formikProps;
        let adjustAmount = parseInt(values.adjustAmount, 10) || 0;
        remainingAdjustAmounts = adjustAmount;

        if (adjustAmount <= 0) {
            Common.showToast("Adjust Amount should be greater than 0");
            return;
        }

        const selectedCount =
            values.bookings?.filter((b) => b && b.isSelected)?.length || 0;
        if (selectedCount === 0) {
            Common.showToast(
                "Please select at least one booking to adjust the amount."
            );
            return;
        }

        bookingList.forEach((booking, index) => {
            if (!booking) {
                return;
            }

            if (remainingAdjustAmounts === 0) {
                formikProps.setFieldValue(`bookings[${index}].isSelected`, false);
                return;
            }
            const isSelected = values.bookings && values.bookings[index]?.isSelected;
            const remainingAmount = booking.remainingAmount || 0;

            if (isSelected && remainingAdjustAmounts > 0) {
                const amountToAdjust = Math.min(
                    remainingAdjustAmounts,
                    remainingAmount
                );
                remainingAdjustAmounts -= amountToAdjust;
                formikProps.setFieldValue(`bookings[${index}].amount`, amountToAdjust);
                formikProps.setFieldValue(`bookings[${index}].id`, booking.id);
            }
        });

        if (remainingAdjustAmounts > 0) {
            Common.showToast(
                `Amount Not completly adjusted. Remaining amount: ${remainingAdjustAmounts}`
            );
        } else {
            setSelectAllChecked(false);
        }
    };

    const GetDetails = async (id) => {
        let response = await Common.ApiService.getInstance().request(
            `Getpayment?id=${id}`
        );

        let d = response?.data?.[0];
        if (formikRef.current) {
            formikRef.current.setValues(d);
        }
    };

    const onSubmitData = async (d, action) => {
        const clubAdjustmentBookings = d.bookings
            .filter((b) => b && b.amount && +b.amount > 0 && b.isSelected === true) // Use isSelected instead of isChecked
            .map((b) => ({
                bookingId: +b.id,
                adjustAmount: +b.amount,
            }));

        debugger;
        let data = {
            ...d,
            id,
            playerBal: +d.balance,
            playerBalAfterAdjustment: +d.playerBalAfterAdjustment,
            adjustAmount: +d.adjustAmount,
            clubAdjustmentBookings,
        };

        if (data.adjustAmount <= 0) {
            Common.showToast(`Adjustment Amount Should be Greater than 0`);
            return;
        }

        if (data.clubAdjustmentBookings.length == 0) {
            Common.showToast(`Their should be atleast one booking for adjustment`);
            return;
        }
        const totalAdjustAmount = clubAdjustmentBookings.reduce(
            (sum, booking) => sum + booking.adjustAmount,
            0
        );
        if (totalAdjustAmount !== +d.adjustAmount) {
            Common.showToast(
                `The sum of the booking amounts ${totalAdjustAmount} should be equal to the total adjustment amount ${d.adjustAmount}.`
            );
            return;
        }

        //if (remainingAdjustAmounts < 0) {
        //    Common.showToast(`Remaining amount is less than zero`);
        //    return;
        //}

        let response = await Common.ApiService.getInstance().request(
            "AdjustBookingPayments",
            data,
            "POST"
        );
        action.setSubmitting(false);
        if (response.status == respEnum.Success) {
            Common.SweetAlert.alert(response.message);
            setTimeout(() => {
                navigate("/app/adjustments");
            }, 1000);
        }
    };

    const calculateRemainingAdjustAmounts = (index) => {
        debugger;
        if (!formikRef.current) return;

        const { bookings, adjustAmount } = formikRef.current.values;

        let totalAmount = 0;
        bookings.forEach((booking) => {
            if (booking && booking.amount) {
                totalAmount += +booking.amount;
            }
        });
        //let adjAmounts = remainingAdjustAmounts;
        //adjAmounts = adjustAmount - totalAmount;
        //if (adjAmounts < 0) {
        //    remainingAdjustAmounts = adjustAmount - totalAmount;
        //    Common.showToast(`Amount Cannot be greater than remaining amount. Remaining amount: ${remainingAdjustAmounts}`);
        //    formikRef.current.setFieldValue(`bookings[${index}].amount`, "");
        //    return;
        //}

        //else if (remainingAdjustAmounts == 0) {
        //    Common.showToast(`Amount completly adjusted. Remaining amount: ${remainingAdjustAmounts}`);
        //}
        //remainingAdjustAmounts = adjAmounts;

        remainingAdjustAmounts = adjustAmount - totalAmount;
    };



    const handleSelectAll = (e) => {
        const isChecked = e.target.checked;
        setSelectAllChecked(isChecked);

        bookingList.forEach((booking, index) => {
            if (booking.remainingAmount > 0) {
                formikRef.current.setFieldValue(
                    `bookings[${index}].isSelected`,
                    isChecked
                );
            }
            if (!isChecked) {
                formikRef.current.setFieldValue(`bookings[${index}].amount`, "");
            }
        });
    };

    const ShowBookingTable = ({ bookingList, formikProps }) => {
        //const handleSelectAll = (e) => {
        //    const isChecked = e.target.checked;
        //    bookingList.forEach((booking, index) => {
        //        if (booking.remainingAmount > 0) {
        //            formikProps.setFieldValue(`bookings[${index}].isSelected`, isChecked);
        //        }
        //    });
        //};

        return (
            <div className="mt-6">
                <div className="overflow-hidden rounded-lg border border-gray-200 bg-white shadow-md">
                    <div className="overflow-x-auto">
                        <table className="w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th hidden>Booking ID</th>
                                    <th scope="col" className="px-4 py-3.5 text-center w-12">
                                        <input
                                            type="checkbox"
                                            onChange={handleSelectAll}
                                            checked={selectAllChecked}
                                            className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                                        />
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-4 py-3.5 text-sm font-semibold text-gray-900"
                                    >
                                        Booking Date
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-4 py-3.5 text-sm font-semibold text-gray-900"
                                    >
                                        Time
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-4 py-3.5 text-sm font-semibold text-gray-900"
                                    >
                                        Court
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-4 py-3.5 text-sm font-semibold text-gray-900"
                                    >
                                        Payment Status
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-4 py-3.5 text-sm font-semibold text-gray-900"
                                    >
                                        Total Payment
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-4 py-3.5 text-sm font-semibold text-gray-900"
                                    >
                                        Remaining
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-4 py-3.5 text-sm font-semibold text-gray-900"
                                    >
                                        Adjust Amount
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-4 py-3.5 text-sm font-semibold text-gray-900"
                                    ></th>
                                </tr>
                            </thead>
                            <tbody className="divide-y text-center divide-gray-200 bg-white">
                                {bookingList.length > 0 ? (
                                    bookingList.map((booking, index) => (
                                        <tr
                                            key={index}
                                            className="transition-colors hover:bg-gray-50"
                                        >
                                            <td className="hidden">
                                                <input
                                                    type="hidden"
                                                    name={`bookings[${index}].id`}
                                                    value={booking.id}
                                                />
                                            </td>
                                            <td className="px-4 py-4 text-center">
                                                {booking.remainingAmount > 0 ? (
                                                    <input
                                                        type="checkbox"
                                                        name={`bookings[${index}].isSelected`}
                                                        checked={
                                                            formikProps.values.bookings &&
                                                            formikProps.values.bookings[index]?.isSelected
                                                        }
                                                        onChange={(e) => {
                                                            formikProps.setFieldValue(
                                                                `bookings[${index}].isSelected`,
                                                                e.target.checked
                                                            );
                                                            if (!e.target.checked) {
                                                                formikProps.setFieldValue(
                                                                    `bookings[${index}].amount`,
                                                                    ""
                                                                );
                                                            }
                                                        }}
                                                        className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                                                    />
                                                ) : null}
                                            </td>
                                            <td className="whitespace-nowrap px-4 py-4 text-sm text-gray-900">
                                                {Common.Utility.ConvertDate(booking.bookingDate)}
                                            </td>
                                            <td className="whitespace-nowrap px-4 py-4 text-sm text-gray-900">
                                                {booking.bookingDetail}
                                            </td>
                                            <td className="whitespace-nowrap px-4 py-4 text-sm text-gray-900">
                                                {booking.courtName}
                                            </td>
                                            <td className="whitespace-nowrap px-4 py-4 text-sm">
                                                {Common.Utility.renderBadge(
                                                    booking.paymentStatus,
                                                    Common.Utility.getKeyByValue(
                                                        PaymentStatusEnum,
                                                        booking.paymentStatus
                                                    )
                                                )}
                                            </td>
                                            <td className="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-900">
                                                {booking.total}
                                            </td>
                                            <td className="whitespace-nowrap px-4 py-4 text-sm text-gray-900">
                                                {booking.remainingAmount}
                                            </td>
                                            <td className="px-4 py-4">
                                                <input
                                                    className="w-full bg-background dark:border-700 px-3 file:border-0 file:bg-transparent file:text-sm file:font-medium read-only:bg-background disabled:cursor-not-allowed disabled:opacity-50 transition duration-300 border-default-300 text-default-500 focus:outline-none focus:border-default-500/50 disabled:bg-default-200 placeholder:text-accent-foreground/50 [&>svg]:stroke-default-600 border rounded-lg h-8 text-xs read-only:leading-8"
                                                    type="number"
                                                    name={`bookings[${index}].amount`}
                                                    placeholder="Enter Amount"
                                                    max={formikProps.values.adjustAmount}
                                                    min={IntMinLevel}
                                                    disabled={
                                                        booking.remainingAmount === 0 ||
                                                        formikProps.values.adjustAmount <= 0 ||
                                                        !formikProps.values.bookings[index]?.isSelected
                                                    }
                                                    value={
                                                        formikProps.values.bookings[index]?.amount ?? ""
                                                    }
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        if (value < IntMinLevel && value !== "") {
                                                            formikProps.setFieldValue(
                                                                `bookings[${index}].amount`,
                                                                ""
                                                            );
                                                        } else if (
                                                            value > remainingAdjustAmounts &&
                                                            value > booking.remainingAmount
                                                        ) {
                                                            Common.showToast(
                                                                `Amount cannot be greater than Remaining Amount`
                                                            );
                                                            formikProps.setFieldValue(
                                                                `bookings[${index}].amount`,
                                                                ""
                                                            );
                                                        } else {
                                                            formikProps.setFieldValue(
                                                                `bookings[${index}].amount`,
                                                                value
                                                            );
                                                        }
                                                        calculateRemainingAdjustAmounts(index);
                                                        formikProps.setFieldValue(
                                                            `bookings[${index}].id`,
                                                            booking.id
                                                        );
                                                    }}
                                                    onKeyDown={() =>
                                                        calculateRemainingAdjustAmounts(index)
                                                    }
                                                    onBlur={() => calculateRemainingAdjustAmounts(index)}
                                                //  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm disabled:bg-gray-100 disabled:cursor-not-allowed"
                                                />
                                            </td>
                                            <td className="px-4 py-4 text-center">
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        setParams({
                                                            ...params,
                                                            bookingId: booking.id,
                                                        });
                                                        navigate("/app/bookingDetails");
                                                    }}
                                                    className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-blue-50 text-blue-600 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                                >
                                                    <Icon icon="heroicons:eye" className="h-5 w-5" />
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td
                                            className="px-4 py-8 text-center text-sm text-gray-500"
                                            colSpan="10"
                                        >
                                            No bookings available.
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <Formik
            initialValues={formInitialValue}
            validationSchema={formValidations}
            innerRef={formikRef}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={onSubmitData}
        >
            {(formikProps) => {
                formikRef.current = formikProps;
                return (
                    <>
                        <div className="flex flex-wrap mb-3">
                            <div className="text-xl font-medium text-default-900 flex-1"></div>
                            <div className="flex-none">
                                <Breadcrumbs>
                                    <BreadcrumbItem onClick={() => navigate("/app/adjustments")}>
                                        Adjustments
                                    </BreadcrumbItem>
                                    <BreadcrumbItem>Adjustment Bookings</BreadcrumbItem>
                                </Breadcrumbs>
                            </div>
                        </div>
                        <div className="space-y-6 mb-5">
                            <Card title={id ? " Update Adjustment" : "Adjust Bookings"}>
                                <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-11 gap-4 p-5">
                                    <div className="flex-grow  col-span-3">
                                        <FormikSearchSelect
                                            formikProps={formikProps}
                                            name={"playerId"}
                                            disabled={id}
                                            data={customerList}
                                            placeholder={"Select Customer"}
                                            className="w-full"
                                            label={"Customer"}
                                            onChange={(e) => { getCustomerBooking(e, formikProps.values.fromDate, formikProps.values.tillDate); }}

                                        />
                                    </div>
                                    <div className="flex-grow  col-span-2">
                                        <FormikInput
                                            formikProps={formikProps}
                                            name={"fromDate"}
                                            label={"Booking From"}
                                            type={"date"}
                                            className="w-full"
                                            onChange={(e) => { getCustomerBooking(formikProps.values.playerId, e, formikProps.values.tillDate); }}
                                        />
                                    </div>

                                    <div className="flex-grow  col-span-2">
                                        <FormikInput
                                            formikProps={formikProps}
                                            name={"tillDate"}
                                            label={"Booking Till "}
                                            type={"date"}
                                            className="w-full"
                                            onChange={(e) => { getCustomerBooking(formikProps.values.playerId, formikProps.values.fromDate, e); }}
                                        />
                                    </div>
                                    <div className="col-span-2">
                                        <FormikInput
                                            formikProps={formikProps}
                                            name={"balance"}
                                            placeholder={"Current Balance"}
                                            label={"Current Balance"}
                                            disabled={true}
                                        />
                                    </div>

                                    <div className="col-span-2">
                                        <FormikInput
                                            formikProps={formikProps}
                                            name={"pendingAmount"}
                                            placeholder={"Unsettled Balance"}
                                            label={"Unsettled Balance"}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-11 gap-4 p-5">
                                    <div className="col-span-2">
                                        <FormikInput
                                            formikProps={formikProps}
                                            name={"adjustAmount"}
                                            placeholder={"Enter Adjust Amount"}
                                            label={"Adjust Amount"}
                                            type={"number"}
                                            max={formikProps.values.balance}
                                            min={IntMinLevel}
                                            disabled={!formikProps.values.playerId}
                                            className=""
                                            onChange={(value) => {
                                                if (value < IntMinLevel && value != "") {
                                                    formikProps.setFieldValue("adjustAmount", "");
                                                    formikProps.setFieldValue(
                                                        "playerBalAfterAdjustment",
                                                        ""
                                                    );
                                                } else if (value > formikProps.values.balance) {
                                                    Common.showToast(
                                                        `Adjust Amount cannot be greater than Balance`
                                                    );
                                                    formikProps.setFieldValue("adjustAmount", "");
                                                    formikProps.setFieldValue(
                                                        "playerBalAfterAdjustment",
                                                        ""
                                                    );
                                                } else {
                                                    formikProps.setFieldValue("adjustAmount", value);
                                                    const balAfterAdjust = formikProps.values.balance
                                                        ? formikProps.values.balance - value
                                                        : 0;
                                                    formikProps.setFieldValue(
                                                        "playerBalAfterAdjustment",
                                                        balAfterAdjust
                                                    );
                                                }
                                                formikProps.values.bookings.forEach((_, index) => {
                                                    formikProps.setFieldValue(
                                                        `bookings[${index}].amount`,
                                                        ""
                                                    );
                                                });
                                                remainingAdjustAmounts = value;
                                            }}
                                        />
                                    </div>

                                    <div className="col-span-2">
                                        <FormikInput
                                            formikProps={formikProps}
                                            name={"playerBalAfterAdjustment"}
                                            placeholder={"Final Customer Balance"}
                                            label={"Final Customer Balance"}
                                            disabled={true}
                                        />
                                    </div>

                                    {formikProps.values.playerId &&
                                        formikProps.values.adjustAmount > 0 && (
                                            <div>
                                                <Button
                                                    onClick={(e) => {
                                                        handleAdjust(formikProps);
                                                    }}
                                                    type="button"
                                                    className="btn-sm mt-7"
                                                >
                                                    Adjust
                                                </Button>
                                            </div>
                                        )}

                                </div>
                            </Card>
                        </div>

                        {bookingList.length > 0 && (
                            <ShowBookingTable
                                bookingList={bookingList}
                                formikProps={formikProps}
                            />
                        )}

                        <div className="flex flex-col items-end mt-5">
                            <Button
                                onClick={(e) => {
                                    e.preventDefault();
                                    formikProps.handleSubmit();
                                }}
                                disabled={formikProps.isSubmitting}
                                type="submit"
                                className="mt-7 btn-sm"
                            >
                                {formikProps.isSubmitting ? "Saving..." : "Save"}
                            </Button>
                        </div>
                    </>
                );
            }}
        </Formik>
    );
}
